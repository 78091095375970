import { useMutation, useQuery } from 'react-query';
import {
  addStudent,
  createProfile,
  deleteClass,
  deleteNoti,
  getAllDivisions,
  getNoti,
  getParent,
  getSchool,
  getStudent,
  getTerms,
  getUser,
  inviteParent,
  readNoti,
  setTerm,
  updateClass,
  updatePass,
  getParentPlan,
  updateUser,
  verifyPass,
  createExtra,
  withdraw,
  getExtras,
  updateExtra,
  deleteExtra,
  createSurcharge,
  getExtraCount,
  updateSurcharge,
  giveDiscount,
  getParentDiscount,
  getParentDetails,
  updateEnroll,
  getFees,
  getSurcharge,
  getExtra,
  deleteDiscount,
  updateBank,
  uploadLogo,
  getLoans,
  getLoanBalance,
  changePass,
  changePin,
  verifyPin,
  updatePin,
  createShareholders,
  verifyBvn,
  getProv,
  createLoan,
  getLoanDetails,
  moveLoan,
  updateLoanElig,
  getLoanSixMonth,
  updateTerm,
  getLoanHistory,
  deleteParent,
  updateParent,
  deleteWard,
  getShareholders,
  updateStudent,
  getDefaultExtras,
  updateDefExtra,
  deleteDefExtra,
  createDefExtra,
  approveParent,
  getInvLink,
  setNewTerm,
  deleteEnrollment,
  getParExtra,
  getParentCount,
  createDept,
  createLevel,
  getDepartments,
  getTotalFee,
  getTotalOutstanding,
  getTotalPaid,
  getTotalOverdue,
  getOverdueParents,
  getParentOverdueDetails,
  deleteRole,
  editRole,
  createRole,
  invAdmin,
  getRoles,
  getPermissions,
  getAdmins,
  deleteStaff,
  getParentOverduePlans,
  editStaff,
  getTotalSchoolFee,
  getPlanTotalPaid,
  addWardExtra,
  removeWardExtra,
  getPlanOutstanding,
  getPlanTotalAmount,
  getParentsDetails,
  getExpectedPayment,
} from '../api/school';
import * as queryKey from '../queryKeys';

export const useGetStudent = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(queryKey.GET_WARD, getStudent, {
    ...options,
  });

  return { data, isLoading, refetch };
};

export const useGetDepartments = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    'getDepartments',
    getDepartments,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetLoans = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(queryKey.GET_LOANS, getLoans, {
    ...options,
  });

  return { data, isLoading, refetch };
};

export const useGetLoanSixMonth = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    queryKey.LOAN_SIX_MONTH,
    getLoanSixMonth,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetLoanBalance = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    queryKey.GET_LOAN_BALANCE,
    getLoanBalance,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetInvLink = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    queryKey.GET_INV_LINK,
    getInvLink,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useInviteParent = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(inviteParent, {
    mutationKey: queryKey.INV_PARENT,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useApproveParent = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(approveParent, {
    mutationKey: queryKey.APPROVE_PARENT,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useUpdateUser = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(updateUser, {
    mutationKey: queryKey.UPDATE_USER,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useUpdatePass = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(updatePass, {
    mutationKey: queryKey.UPDATE_PASS,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useUpdatePin = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(updatePin, {
    mutationKey: queryKey.UPDATE_PIN,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useUpdateLoanElig = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(updateLoanElig, {
    mutationKey: queryKey.UPDATE_LOAN_ELIGIBILITY,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useVerifyBvn = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(verifyBvn, {
    mutationKey: queryKey.VERIFY_BVN,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useGetParentPlan = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(getParentPlan, {
    mutationKey: 'getParentPlan',
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useCreateShareholders = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(createShareholders, {
    mutationKey: queryKey.CREATE_SHAREHOLDERS,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useChangePass = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(changePass, {
    mutationKey: queryKey.CHANGE_PASS,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useChangePin = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(changePin, {
    mutationKey: queryKey.CHANGE_PIN,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useVerifyPass = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(verifyPass, {
    mutationKey: queryKey.VERIFY_PASS,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useVerifyPin = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(verifyPin, {
    mutationKey: queryKey.VERIFY_PIN,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useCreateExtra = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(createExtra, {
    mutationKey: queryKey.CREATE_EXTRA,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useCreateDefExtra = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(createDefExtra, {
    mutationKey: queryKey.CREATE_DEF_EXTRA,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useUpdateExtra = (options = {}) => {
  const { mutate, isLoading } = useMutation(updateExtra, {
    mutationKey: queryKey.UPDATE_EXTRA,
    ...options,
  });

  return { mutate, isLoading };
};

export const useUpdateDefExtra = (options = {}) => {
  const { mutate, isLoading } = useMutation(updateDefExtra, {
    mutationKey: queryKey.UPDATE_DEF_EXTRA,
    ...options,
  });

  return { mutate, isLoading };
};

export const useWithdraw = (options = {}) => {
  const { mutate, isLoading } = useMutation(withdraw, {
    mutationKey: queryKey.WITHDRAW,
    ...options,
  });

  return { mutate, isLoading };
};

export const useAddStudent = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(addStudent, {
    mutationKey: queryKey.ADD_SCHOOL,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useUpdateStudent = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(updateStudent, {
    mutationKey: queryKey.UDPATE_STUDENT,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useSetTerm = (options = {}) => {
  const { mutate, isLoading } = useMutation(setTerm, {
    mutationKey: queryKey.SET_TERM,
    ...options,
  });

  return { mutate, isLoading };
};

export const useSetNewTerm = (options = {}) => {
  const { mutate, isLoading } = useMutation(setNewTerm, {
    mutationKey: 'SET_NEW_TERM',
    ...options,
  });

  return { mutate, isLoading };
};

export const useUpdateTerm = (options = {}) => {
  const { mutate, isLoading } = useMutation(updateTerm, {
    mutationKey: queryKey.UPDATE_TERM,
    ...options,
  });

  return { mutate, isLoading };
};

export const useCreateProfile = (options = {}) => {
  const { mutate, isLoading } = useMutation(createProfile, {
    mutationKey: queryKey.CREATE_PROFILE,
    ...options,
  });

  return { mutate, isLoading };
};

export const useCreateDept = (options = {}) => {
  const { mutate, isLoading } = useMutation(createDept, {
    mutationKey: 'CREATE_Dept',
    ...options,
  });

  return { mutate, isLoading };
};

export const useCreateLevel = (options = {}) => {
  const { mutate, isLoading } = useMutation(createLevel, {
    mutationKey: 'CREATE_createLevel',
    ...options,
  });

  return { mutate, isLoading };
};

export const useUpdateClass = (options = {}) => {
  const { mutate, isLoading } = useMutation(updateClass, {
    mutationKey: queryKey.UPDATE_CLASS,
    ...options,
  });

  return { mutate, isLoading };
};

export const useGetParExtra = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(getParExtra, {
    mutationKey: 'getParExtra',
    ...options,
  });

  return { mutate, isLoading, data };
};

export const useUpdateParent = (options = {}) => {
  const { mutate, isLoading } = useMutation(updateParent, {
    mutationKey: queryKey.UPDATE_PARENT,
    ...options,
  });

  return { mutate, isLoading };
};

export const useAddWardExtra = (options = {}) => {
  const { mutate, isLoading } = useMutation(addWardExtra, {
    mutationKey: 'addWardExtra',
    ...options,
  });

  return { mutate, isLoading };
};

export const useRemoveWardExtra = (options = {}) => {
  const { mutate, isLoading } = useMutation(removeWardExtra, {
    mutationKey: 'removeWardExtra',
    ...options,
  });

  return { mutate, isLoading };
};

export const useReadNoti = (options = {}) => {
  const { mutate, isLoading } = useMutation(readNoti, {
    mutationKey: queryKey.READ_NOTI,
    ...options,
  });

  return { mutate, isLoading };
};

export const useCreateSurcharge = (options = {}) => {
  const { mutate, isLoading } = useMutation(createSurcharge, {
    mutationKey: queryKey.CREATE_SURCHARGE,
    ...options,
  });

  return { mutate, isLoading };
};

export const useUpdateSurcharge = (options = {}) => {
  const { mutate, isLoading } = useMutation(updateSurcharge, {
    mutationKey: queryKey.UPDATE_SURCHARGE,
    ...options,
  });

  return { mutate, isLoading };
};

export const useUpdateEnroll = (options = {}) => {
  const { mutate, isLoading } = useMutation(updateEnroll, {
    mutationKey: queryKey.UPDATE_ENROLL,
    ...options,
  });

  return { mutate, isLoading };
};

export const useDeleteClass = (options = {}) => {
  const { mutate, isLoading } = useMutation(deleteClass, {
    mutationKey: queryKey.DELETE_CLASS,
    ...options,
  });

  return { mutate, isLoading };
};

export const useDeleteEnrollment = (options = {}) => {
  const { mutate, isLoading } = useMutation(deleteEnrollment, {
    mutationKey: queryKey.DELETE_CLASS,
    ...options,
  });

  return { mutate, isLoading };
};

export const useDeleteWard = (options = {}) => {
  const { mutate, isLoading } = useMutation(deleteWard, {
    mutationKey: queryKey.DELETE_WARD,
    ...options,
  });

  return { mutate, isLoading };
};

export const useDeleteParent = (options = {}) => {
  const { mutate, isLoading } = useMutation(deleteParent, {
    mutationKey: queryKey.DELETE_PARENT,
    ...options,
  });

  return { mutate, isLoading };
};

export const useUpdateBank = (options = {}) => {
  const { mutate, isLoading } = useMutation(updateBank, {
    mutationKey: queryKey.UPDATE_BANK,
    ...options,
  });

  return { mutate, isLoading };
};

export const useUploadLogo = (options = {}) => {
  const { mutate, isLoading } = useMutation(uploadLogo, {
    mutationKey: queryKey.UPLOAD_LOGO,
    ...options,
  });

  return { mutate, isLoading };
};

export const useDeleteNoti = (options = {}) => {
  const { mutate, isLoading } = useMutation(deleteNoti, {
    mutationKey: queryKey.DELETE_NOTI,
    ...options,
  });

  return { mutate, isLoading };
};

export const useCreateLoan = (options = {}) => {
  const { mutate, isLoading } = useMutation(createLoan, {
    mutationKey: queryKey.CREATE_LOAN,
    ...options,
  });

  return { mutate, isLoading };
};

export const useDeleteExtra = (options = {}) => {
  const { mutate, isLoading } = useMutation(deleteExtra, {
    mutationKey: queryKey.DELETE_EXTRA,
    ...options,
  });

  return { mutate, isLoading };
};

export const useDeleteDefExtra = (options = {}) => {
  const { mutate, isLoading } = useMutation(deleteDefExtra, {
    mutationKey: queryKey.DEL__DEF_EXTRA,
    ...options,
  });

  return { mutate, isLoading };
};

export const useDeleteDisc = (options = {}) => {
  const { mutate, isLoading } = useMutation(deleteDiscount, {
    mutationKey: queryKey.DEL_DISCOUNT,
    ...options,
  });

  return { mutate, isLoading };
};

export const useGiveDiscount = (options = {}) => {
  const { mutate, isLoading } = useMutation(giveDiscount, {
    mutationKey: queryKey.GIVE_DICOUNT,
    ...options,
  });

  return { mutate, isLoading };
};

export const useGetSurcharge = (type = '', options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_SURCHARGE, type],
    getSurcharge,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetUser = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(queryKey.GET_USER, getUser, {
    ...options,
  });

  return { data, isLoading, refetch };
};

export const useGetShareholders = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    queryKey.GET_SHAREHOLDERS,
    getShareholders,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetProv = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    queryKey.GET_LOAN_PROVIDERS,
    getProv,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetParentDiscount = (id = '', options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_PARENT_DISCOUNT, id],
    getParentDiscount,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetParentDetails = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(getParentDetails, {
    mutationKey: queryKey.GET_PARENT_DETAILS,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useGetParentOverdueDetails = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(getParentOverdueDetails, {
    mutationKey: 'getParentOverdueDetails',
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useGetExtras = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(getExtra, {
    mutationKey: queryKey.GET_EXTRA,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useGetExtra = (id = '', options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_EXTRA, id],
    getExtras,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetParentsDetails = (id = '', options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_PARENT_DETAILS, id],
    getParentsDetails,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetLoanDetails = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(getLoanDetails, {
    mutationKey: queryKey.GET_LOAN_DETAILS,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useGetFee = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(getFees, {
    mutationKey: queryKey.GET_FEES,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useMoveLoan = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(moveLoan, {
    mutationKey: queryKey.MOVE_LOAN,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useGetExtraCount = (name = '', id = '', options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_EXTRA_COUNT, name, id],
    getExtraCount,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetParent = (status = '', page = '', options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_PARENT, status, page],
    getParent,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetParentCount = (status = '', options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    ['parentCount', status],
    getParentCount,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetSchool = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_SCHOOL],
    getSchool,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetNoti = (options = {}) => {
  const { data, isLoading, refetch } = useQuery([queryKey.GET_NOTI], getNoti, {
    ...options,
  });

  return { data, isLoading, refetch };
};

export const useGetLoanHistory = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.LOAN_HISTORY],
    getLoanHistory,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetAllDivisions = (id = '', options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_DIVISION, id],
    getAllDivisions,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetDefaultExtras = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_DEFAULT_EXTRAS],
    getDefaultExtras,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetTerms = (id = '', options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_TERM, id],
    getTerms,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetTotalFee = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_TOTAL_FEE],
    getTotalFee,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetTotalOutstanding = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_TOTAL_OUTSTANDING],
    getTotalOutstanding,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetTotalPaid = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_TOTAL_PAID],
    getTotalPaid,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetTotalOverdue = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_TOTAL_OVERDUE],
    getTotalOverdue,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetRole = (options = {}) => {
  const { data, isLoading, refetch } = useQuery('GET_ROLES', getRoles, {
    ...options,
  });

  return { data, isLoading, refetch };
};

export const useGetPermissions = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    'GET_PERMISSIONS',
    getPermissions,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetOverdueParents = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_OVERDUE_PARENTS],
    getOverdueParents,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetAdmins = (options = {}) => {
  const { data, isLoading, refetch } = useQuery('getAdmins', getAdmins, {
    ...options,
  });

  return { data, isLoading, refetch };
};

export const useCreateRole = (options = {}) => {
  const { mutate, isLoading } = useMutation(createRole, {
    mutationKey: 'CREATE_ROLES',
    ...options,
  });
  return { mutate, isLoading };
};

export const useEditRole = (options = {}) => {
  const { mutate, isLoading } = useMutation(editRole, {
    mutationKey: 'EDIT_ROLES',
    ...options,
  });
  return { mutate, isLoading };
};

export const useDeleteRole = (options = {}) => {
  const { mutate, isLoading } = useMutation(deleteRole, {
    mutationKey: 'DEL_ROLES',
    ...options,
  });
  return { mutate, isLoading };
};

export const useDeleteStaff = (options = {}) => {
  const { mutate, isLoading } = useMutation(deleteStaff, {
    mutationKey: 'useDeleteStaff',
    ...options,
  });
  return { mutate, isLoading };
};

export const useInvAdmin = (options = {}) => {
  const { mutate, isLoading } = useMutation(invAdmin, {
    mutationKey: 'INV_ADMIN',
    ...options,
  });
  return { mutate, isLoading };
};

export const useEditStaff = (options = {}) => {
  const { isLoading, data, mutate } = useMutation(editStaff, {
    mutationKey: ['EDIT_STAFF'],
    ...options,
  });

  return { isLoading, data, mutate };
};
export const useGetParentOverduePlans = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(getParentOverduePlans, {
    mutationKey: queryKey.GET_PARENT_DETAILS,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useGetTotalSchoolFee = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_TOTAL_SCHOOL_FEE],
    getTotalSchoolFee,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetPlanTotalPaid = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(getPlanTotalPaid, {
    mutationKey: queryKey.GET_PLAN_TOTAL_PAID,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useGetPlanOutstanding = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(getPlanOutstanding, {
    mutationKey: queryKey.GET_PLAN_OUTSTANDING,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useGetPlanTotalAmount = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(getPlanTotalAmount, {
    mutationKey: queryKey.GET_PLAN_TOTAL_AMOUNT,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useGetExpectedPayment = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(getExpectedPayment, {
    mutationKey: queryKey.GET_EXPECTED_PAYMENT,
    ...options,
  });

  return { mutate, data, isLoading };
};
