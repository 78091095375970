import {
  AgentIcon,
  AuditIcon,
  DashboardIcon,
  LoanIcon,
  NotificationIcon,
  ParentIcon,
  PortalIcon,
  RolesIcon,
  SettingsIcon,
  TransactionIcon,
} from '../../assets';
import {
  cap,
  edit,
  note,
  pr,
  slide1,
  slide2,
  slide3,
  slide4,
  trash,
} from '../../assets/exports';

export const slideshow = [slide1, slide2, slide3, slide4];

export const adminAct = [
  {
    id: 1,
    title: 'View Roles',
    icon: note,
  },
  {
    id: 2,
    title: 'Update Staff Details',
    icon: note,
  },
  {
    id: 3,
    title: 'Delete Staff',
    icon: trash,
  },
];

export const invAgentHeaders = [
  'Full Name',
  'Email Address',
  'Roles',
  'Invited By',
  'Status',
];

export const agentHeaders = [
  'Full Name',
  'Email Address',
  'Roles',
  'Status',
  '',
];

export const extraType = [
  {
    id: 1,
    name: 'Recurrent',
    value: 'recurrent',
  },
  {
    id: 2,
    name: 'One-off',
    value: 'one-off',
  },
];

export const filters = [
  {
    id: 1,
    name: 'Last 30 days',
    value: '30',
  },
  {
    id: 2,
    name: 'Last 14 days',
    value: '14',
  },
  {
    id: 3,
    name: 'Last Week',
    value: '7',
  },
  {
    id: 4,
    name: 'Yesterday',
    value: '1',
  },
];
export const test = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
];

export const permissionLabels = {
  viewDashboard: 'View dashboard',
  viewParentAndWards: 'View parent and wards',
  enrollParentAndWards: 'Enroll parent and wards',
  updateParentAndWards: 'Update parent and wards',
  approveEnrollment: 'Approve enrollment',
  deleteParentAndWards: 'Delete parent and wards',
  deleteSchoolRecord: 'Delete school record',
  viewInvoiceAndReceipt: 'View invoice and receipt',
  addClassRecords: 'Add class records',
  updateClassRecords: 'Update class records',
  viewTransactionPage: 'View transaction page',
  viewLoanFacility: 'View loan facility',
  modifyLoanFacility: 'Modify loan facility',
  createAndEditRole: 'Create  and edit role',
  addAndEditMember: 'Add and edit team member',
  updateAccountDetails: 'Update account details',
  createAndEditEnrollment: 'Create and edit enrollment',
  updateSurcharge: 'Update surcharge',
  updateSchoolRecord: 'Update School Record',
  viewSchool: 'View school',
  addBankAccountDetails: 'Update account details',
  addStaff: 'Add staff',
  createTransactionPin: 'Create transaction pin',
  deleteSchool: 'Delete School',
  revokeInviteLink: 'Revoke invite link',
  getEnrollment: 'Get enrollment',
  viewShareHolders: 'View Shareholders',
  viewSurcharge: 'View surcharge',
  viewClassRecords: 'View class records',
  viewBankAccountDetails: 'View bank Account Details',
  viewSchoolWallet: 'View School wallet',
};

export const general = [
  {
    id: 1,
    name: 'Dashboard',
    path: '/dashboard',
    icon: <DashboardIcon fill="#878b95" />,
    sec: <DashboardIcon fill="#2463EB" />,
  },
  {
    id: 2,
    name: 'School Portal',
    path: '/school-portal',
    icon: <PortalIcon fill="#878b95" />,
    sec: <PortalIcon fill="#2463EB" />,
  },
  {
    id: 3,
    name: 'Guardian Mgt',
    path: '/guardian-mgt',
    icon: <ParentIcon fill="#878b95" />,
    sec: <ParentIcon fill="#2463EB" />,
  },
];

export const payments = [
  {
    id: 1,
    name: 'Transaction Log',
    path: '/transaction-log',
    icon: <TransactionIcon fill="#878b95" />,
    sec: <TransactionIcon fill="#2463EB" />,
  },
  {
    id: 2,
    name: 'Audit Log',
    path: '/audit-log',
    icon: <AuditIcon fill="#878b95" />,
    sec: <AuditIcon fill="#2463EB" />,
  },
  {
    id: 3,
    name: 'Loan Facility',
    path: '/loan-facility',
    icon: <LoanIcon fill="#878b95" />,
    sec: <LoanIcon fill="#2463EB" />,
  },
];

export const loanHeader = ['Loan Date', 'Requested Amount', 'status', ''];

export const loanModalGrid = [
  {
    id: 1,
    title: 'Loan Amount',
  },
  {
    id: 2,
    title: 'Amount Repaid',
  },
  {
    id: 3,
    title: 'Outstanding',
  },
  {
    id: 4,
    title: 'Overdue',
  },
];

export const loanHistoryHeader = [
  'Loan Date',
  'Amount',
  'Transaction Type',
  'status',
  '',
];

export const activeLoanHeader = [
  'Loan Date',
  'Requested Amount',
  'Approved Amount',
  'status',
  '',
];

export const share = ['Utility Bill', 'Guarantee', ''];

export const schoolDoc = [
  'CAC Certificate',
  'Memart Doc',
  'Bank Statement',
  'Government Approval',
  'Board Resolution',
];

export const ids = [
  'National Identity Card',
  "Voter's Card",
  'International Passport',
  "Driver's License",
];

export const agentTab = ['All Members', 'Invitations'];

export const accounts = [
  {
    id: 1,
    name: 'Roles',
    path: '/roles',
    icon: <RolesIcon fill="#878b95" />,
    sec: <RolesIcon fill="#2463EB" />,
  },
  {
    id: 2,
    name: 'Teams',
    path: '/team-mgt',
    icon: <AgentIcon fill="#878b95" />,
    sec: <AgentIcon fill="#2463EB" />,
  },
  {
    id: 3,
    name: 'Notification',
    path: '/notifications',
    icon: <NotificationIcon fill="#878b95" />,
    sec: <NotificationIcon fill="#2463EB" />,
  },
  {
    id: 4,
    name: 'Settings',
    path: '/settings',
    icon: <SettingsIcon fill="#878b95" />,
    sec: <SettingsIcon fill="#2463EB" />,
  },
];

export const activeStyle = {
  backgroundColor: '#F1F5FE',
  alignItems: 'center',
  display: 'flex',
  color: '#182444',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '21px',
  margin: '0 -20px 8px',
  padding: '13px 25px',
  borderRight: '4px solid #2463EB',
};

export const roleHeaders = ['Role Name', 'Created By', 'Team Members', ''];

export const roleAct = [
  {
    id: 1,
    title: 'View Details',
    icon: note,
  },
  {
    id: 2,
    title: 'Update Details',
    icon: edit,
  },
  {
    id: 2,
    title: 'Delete',
    icon: trash,
  },
];

export const setup = [
  'Create Transaction Pin',
  'Complete Wallet Setup',
  'Set up School Profile',
  'Onboard Guardians',
];

export const settings = [
  'Profile Information',
  'Account',
  'Security & Login',
  'Surcharge',
  'Enrollment',
];

export const classes = [
  'Creche',
  'KG 1',
  'KG 2',
  'Nursery 1',
  'Nursery 2',
  'Play Group 1',
  'Play Group 2',
  'Basic 1',
  'Basic 2',
  'Basic 3',
  'Basic 4',
  'Basic 5',
  'Basic 6',
  'Primary 1',
  'Primary 2',
  'Primary 3',
  'Primary 4',
  'Primary 5',
  'Primary 6',
  'Grade 1',
  'Grade 2',
  'Grade 3',
  'Grade 4',
  'Grade 5',
  'Grade 6',
  'LDC1',
  'LDC2',
  'LDC3',
  'LDC4',
  'JSS 1',
  'JSS 2',
  'JSS 3',
  'SSS 1',
  'SSS 2',
  'SSS 3',
  'Preparatory School',
  'Pre-nursery ',
  'Pre-primary',
  'Year 1',
  'Year 2',
  'Year 3',
  'Year 4',
  'Year 5',
];

export const departments = [
  'Chemistry',
  'Water Resources Engineering',
  'Mechanical Engineering',
  'Chemical Engineering',
  'Agric & BioSystems Engineering',
  'Electrical Engineering',
  'Computer Engineering Engineering',
  'Materials & Metallurgical Engineering',
];

export const terms = ['First Term', 'Second Term', 'Third Term'];

export const classRecord = [
  {
    id: 1,
    title: 'Total Guardian',
  },
  {
    id: 2,
    title: 'Total Daily Plan',
  },
  {
    id: 3,
    title: 'Total Weekly Plan',
    value: '405',
  },
  {
    id: 4,
    title: 'Total Monthly Plan',
  },
  {
    id: 5,
    title: 'Total One off Plan',
  },
  {
    id: 6,
    title: 'Total Full Year Plan',
  },
];

export const classHeader = [
  'Class',
  'Num of Days in a Term',
  'Num of Weeks in a Term',
  'Num of Months in a Term',
  'School Fees',
  '',
];

export const defExtraHeader = ['Title', 'Fees', 'Type', ''];
export const extraHeader = ['Title', 'Num of Students', 'Fees', 'Type', ''];

export const totalFee = [
  {
    id: 1,
    title: 'Total School Fees Per Term',
    fees: '150,000',
  },
  {
    id: 2,
    title: 'Total Revenue',
    fees: '90,000',
  },
  {
    id: 3,
    title: 'Total Outstanding',
    fees: '75,000',
  },
  {
    id: 4,
    title: 'Total Overdue',
    fees: '30,570',
  },
];

export const overview = [
  {
    id: 1,
    title: 'Total School Fees',
    fees: '₦ 150,000',
  },
  {
    id: 2,
    title: 'Total Enrolled Guardians',
    fees: '35',
    img: cap,
  },
  {
    id: 3,
    title: 'Total Active Guardians',
    fees: 'Weekly',
    img: pr,
  },
  {
    id: 4,
    title: 'Total Inactive Guardians',
    fees: '3',
    img: pr,
  },
];

export const parentHeader = [
  'Date',
  'Name',
  'Amount',
  'Sub. Plan',
  'Channel',
  '',
];

export const tranFilter = [
  'All Transactions',
  'Fund Transfer',
  'Fund Deposit',
  'ATM Card Transaction',
];

export const parentsHeader = [
  'Name',
  'Email Address',
  'Phone Number',
  'Payment Plan',
  '',
];

export const overdueHeaders = [
  'Full Name',
  'Email Address',
  'Phone Number',
  'Number of Wards',
  'Active Plan',
  '',
];

export const schoolHeader = [
  'Date',
  'Amount',
  'Sender',
  'Balance before',
  'Balance after',
  'Service Charge',
  'Status',
  '',
];

export const schoolsHeader = [
  'Date',
  'Amount',
  'Balance before',
  'Balance after',
  'Status',
  '',
];

export const extrass = [
  {
    id: 0,
    name: 'Lunch',
    fees: 20000,
  },
  {
    id: 1,
    name: 'Transport',
    fees: 30000,
  },
  {
    id: 2,
    name: 'Sport',
    fees: 10000,
  },
  {
    id: 3,
    name: 'Uniforms',
    fees: 50000,
  },
];

export const parentAction = [
  {
    id: 1,
    title: 'View Details',
    icon: note,
  },
];

export const wardAction = [
  {
    id: 1,
    title: 'Update Ward',
    icon: note,
  },
  {
    id: 2,
    title: 'Delete Ward',
    icon: trash,
  },
];

export const classAction = [
  {
    id: 1,
    title: 'Class Details',
    icon: note,
  },
  {
    id: 2,
    title: 'View Extras',
    icon: note,
  },
  {
    id: 3,
    title: 'Edit Class Record Details',
    icon: edit,
  },
  // {
  //   id: 4,
  //   title: "Delete Class record",
  //   icon: trash,
  // },
];
export const tranAction = [
  {
    id: 1,
    title: 'Class Details',
    icon: note,
  },
];

export const extraAction = [
  {
    id: 1,
    title: 'Update Details',
    icon: note,
  },
  {
    id: 3,
    title: 'Delete record',
    icon: trash,
  },
];

export const customers = [
  {
    id: 1,
    title: 'Total Income',
  },
  {
    id: 2,
    title: 'Total Withdrawal',
  },
  {
    id: 3,
    title: 'Balance',
  },
];

export const dashFlow = [
  {
    id: 1,
    title: 'Total Daily Income',
  },
  {
    id: 2,
    title: 'Total Weekly Income',
  },
  {
    id: 3,
    title: 'Total Monthly Income',
  },
];

export const auditGrid = [
  {
    id: 1,
    title: 'System Balance',
    val: '₦ 35,570,147',
  },
  {
    id: 2,
    title: 'Total Enrolled Guardians',
    val: '513',
  },
  {
    id: 3,
    title: 'Total Active Guardians',
    val: '320',
  },
  {
    id: 4,
    title: 'Total Inactive Guardians',
    val: '193',
  },
];

export const reconFilter = [
  'Reported Transactions',
  'Suspended/Flagged Accounts',
  'Archived Doc',
];

export const tranTab = ['Guardians Activities', 'School Activities'];
export const auditTab = ['Audit Log', 'Complaints'];
export const tranTabs = [
  'Plan Payment',
  'Withdrawals',
  'Enrollment Bonus',
  'Overdue Parents',
];
export const studTabs = [
  'Students Details',
  'Transactions',
  'Extras',
  'Overdue',
];

export const portalTabs = ['Class Records', 'Extras'];

export const parentTab = [
  'All Guardians',
  'Active',
  'Invitation',
  'Pending Enrollments',
];
export const notiTab = ['All', 'Read', 'Unread'];
export const loanTab = [
  'Loan Statistics',
  'Loan History',
  'Transaction History',
];

export const userflow = [
  {
    id: 2,
    title: 'Total Amount',
  },
  {
    id: 3,
    title: 'Amount Paid',
  },
  {
    id: 4,
    title: 'Amount Left',
  },
  {
    id: 5,
    title: 'Amount Overdue',
  },
];

export const userflows = [
  {
    id: 1,
    title: 'Total Amount',
  },
  {
    id: 2,
    title: 'Total Outstanding',
  },
  {
    id: 3,
    title: 'Amount Paid',
  },
  {
    id: 4,
    title: 'Amount Overdue',
  },
];
export const parentDetailsHeader = ['Name', 'Class', 'School Fee', ''];
export const extraDetailsHeader = ['Name', 'Extra', 'Fee', 'Type', ''];
export const parentTransHeader = ['Date', 'Amount', 'Service Charge', 'Status'];

export const parOverdueHeader = ['Enrollment', 'Amount'];
