import React, { useEffect, useRef, useState } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Box,
  Flex,
  Text,
  Image,
  InputGroup,
  InputRightElement,
  Button,
  Select,
} from "@chakra-ui/react";
import { classs, naira } from "../../assets/exports";
import { AiOutlineClose } from "react-icons/ai";
import { classes, extraType } from "../common/constants";
import CustomInput from "../common/CustomInput";
import ClassList from "../common/ClassList";
import SuccessModal from "./SuccessModal";
import { useCreateExtra, useCreateProfile } from "../../services/query/school";
import useCustomToast from "../../utils/notifications";
import { IoMdAdd } from "react-icons/io";

const AddClassDrawer = ({
  isOpen,
  id,
  classe,
  onClose,
  refetchSchool,
  refetch,
}) => {
  const [show, setShow] = useState(false);
  const [showExtra, setShowExtra] = useState(false);
  const [result, setResult] = useState("");
  const [success, setSuccess] = useState(false);
  const [filter, setFilter] = useState("");
  const [values, setValues] = useState({
    class: "",
    fees: "",
    extraFee: "",
    extraName: "",
    extraType: "",
    id: "",
  });
  const [extras, setExtras] = useState({
    extraFee: "",
    extraName: "",
    extraType: "",
  });
  const lists = classes?.filter(
    (list) => !filter || list.toLowerCase().startsWith(filter.toLowerCase())
  );
  const searchBoxRef = useRef(null);

  useEffect(() => {
    const node = searchBoxRef.current;
    if (show) {
      node && node.focus();
    } else {
      node && node.blur();
    }
  }, [show]);
  let isClassPresent = [];

  if (classe) {
    isClassPresent = classe?.some((data) => data.name === values.class);
  }
  const { errorToast, warningToast, successToast } = useCustomToast();
  const [loading, setLoading] = useState(false);
  const { mutate, isLoading } = useCreateProfile({
    onSuccess: (res) => {
      setResult(res);
      successToast(res?.message);
      refetch();
    },
    onError: (res) => {
      errorToast(
        res?.response?.data?.message || res?.message || "An Error Occured"
      );
    },
  });
  const close = () => {
    sessionStorage.removeItem("extras");
    setShowExtra(false);
    onClose();
    setInputs([]);
    setExtras({
      ...values,
      extraFee: "",
      extraName: "",
      extraType: "",
    });
    setValues({
      ...values,
      class: "",
      fees: "",
      id: "",
    });
    setTimeout(() => {
      setResult("");
    }, 2000);
  };

  const getNumber = (str) => {
    const arr = str.split("");
    const out = [];
    for (let i = 0; i < arr.length; i++) {
      if (!isNaN(arr[i])) {
        out.push(arr[i]);
      }
    }
    return Number(out?.join(""));
  };

  const updateTextView = (event) => {
    const num = getNumber(event.target.value);
    if (num === 0) {
      setValues({
        ...values,
        fees: "",
      });
    } else {
      setValues({
        ...values,
        fees: num.toLocaleString(),
      });
    }
  };

  const handleSubmit = () => {
    mutate({
      name: values.class,
      fees: values.fees.replace(/\D/g, ""),
      status: "Active",
      schoolId: id?.user?.School?.id,
    });
  };

  const [inputs, setInputs] = useState([]);

  useEffect(() => {
    const storedInputs = JSON.parse(sessionStorage.getItem("extras"));
    if (storedInputs) {
      setInputs(storedInputs);
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("extras", JSON.stringify(inputs));
  }, [inputs]);

  const handleAddInput = () => {
    setInputs([...inputs, extras]);
    setExtras({ extraName: "", extraFee: "", extraType: "" });
  };

  const getNumbers = (str) => {
    const arr = str.split("");
    const out = [];
    for (let i = 0; i < arr.length; i++) {
      if (!isNaN(arr[i])) {
        out.push(arr[i]);
      }
    }
    return Number(out?.join(""));
  };
  const updateTextViews = (event) => {
    const nums = getNumbers(event.target.value);
    if (nums === 0) {
      setExtras({
        ...extras,
        extraFee: "",
      });
    } else {
      setExtras({
        ...extras,
        extraFee: nums.toLocaleString(),
      });
    }
  };

  const { mutate: extraMutate, isLoading: isEXtra } = useCreateExtra({
    onSuccess: (res) => {
      successToast(res?.message);
      refetch();
      refetchSchool();
      handleAddInput();
    },
    onError: (res) => {
      errorToast(
        res?.response?.data?.message || res?.message || "An Error Occured"
      );
    },
  });
  const handleExtra = () => {
    extraMutate({
      name: extras.extraName,
      fee: Number(extras.extraFee.replace(/\D/g, "")),
      divisionId: result?.data?.id,
      type: extras?.extraType,
      status: "Active",
      schoolId: id?.user?.School?.id,
    });
  };

  return (
    <Drawer
      autoFocus={false}
      size="sm"
      isOpen={isOpen}
      placement="right"
      onClose={close}
    >
      <DrawerOverlay />
      <DrawerContent py="20px" bgColor="#fff" color="#000">
        <DrawerBody p={0} overflowY="scroll">
          <Flex
            flexDir="column"
            w="full"
            align="center"
            minH="100vh"
            overflowY="scroll"
          >
            <Flex
              justifyContent="space-between"
              align="center"
              bg="#fff"
              w="full"
              zIndex={2}
              pos="sticky"
              top="0"
              pb="15px"
              px="20px"
              borderBottom="1px solid #E0E0E0"
            >
              <Flex gap="16px" align="center">
                <Image src={classs} w="40px" h="40px" />
                <Text fontWeight={600} fontSize="20px">
                  Create a Class/Extras
                </Text>
              </Flex>
              <Box
                border="1px solid #E0E0E0"
                cursor="pointer"
                _hover={{ boxShadow: "lg" }}
                bg="#f2f2f2"
                onClick={close}
                rounded="full"
                p="2"
              >
                <AiOutlineClose />
              </Box>
            </Flex>

            <Box
              mt="37px"
              px="30px"
              w="full"
              flex="1"
              pos="relative"
              pb="30px"
              zIndex={5}
            >
              <Text>Provide Information</Text>
              <Flex flexDir="column" gap="15px" mt="24px" w="full">
                <ClassList
                  values={values}
                  setShow={setShow}
                  opt
                  res={result}
                  setFilter={setFilter}
                  filter={filter}
                  show={show}
                  searchBoxRef={searchBoxRef}
                  lists={lists}
                  setValues={setValues}
                />

                <InputGroup>
                  <CustomInput
                    mb
                    opt
                    value={values.fees}
                    onChange={updateTextView}
                    type="text"
                    holder="Enter School fees"
                  />
                  <InputRightElement h="60px">
                    <Image src={naira} opacity={0.8} />
                  </InputRightElement>
                </InputGroup>
              </Flex>

              {!result && (
                <Flex justifyContent="flex-end" my="20px">
                  <Button
                    isLoading={isLoading}
                    onClick={() =>
                      isClassPresent
                        ? warningToast("Class already exists")
                        : handleSubmit()
                    }
                  >
                    Create
                  </Button>
                </Flex>
              )}

              {!showExtra ? (
                <Flex
                  align="center"
                  gap="10px"
                  mt={5}
                  w="fit-content"
                  opacity={result && !showExtra ? 1 : 0.6}
                  color="blueBg"
                  onClick={() => {
                    !result && showExtra
                      ? ""
                      : result && !showExtra
                      ? setShowExtra(true)
                      : showExtra && "";
                  }}
                  cursor={result && !showExtra ? "pointer" : ""}
                  _hover={{
                    textDecor: result && !showExtra ? "underline" : "",
                    textUnderlineOffset: 3,
                  }}
                >
                  <IoMdAdd />
                  <Text fontSize="14px">Add Extra Record</Text>
                </Flex>
              ) : (
                <Text fontSize="14px" mt={5}>
                  Extra Records
                </Text>
              )}

              {showExtra && !isLoading && (
                <Box mt="15px">
                  <Flex gap="15px" w="full" flexDir="column">
                    <CustomInput
                      mb
                      opt
                      value={extras?.extraName}
                      onChange={(e) =>
                        setExtras({
                          ...extras,
                          extraName: e.target.value,
                        })
                      }
                      type="text"
                      holder="Enter Extra Name"
                    />
                    <InputGroup>
                      <CustomInput
                        mb
                        opt
                        value={extras.extraFee}
                        onChange={updateTextViews}
                        type="text"
                        holder="Enter fees"
                      />
                      <InputRightElement h="50px">
                        <Image src={naira} opacity={0.8} />
                      </InputRightElement>
                    </InputGroup>
                    <Select
                      placeholder="Select Type"
                      cursor="pointer"
                      borderRadius="10px"
                      h="60px"
                      bg={extras?.extraType ? "#E8EEFD" : "unset"}
                      border={
                        extras?.extraType
                          ? "1px solid rgba(36, 99, 235, 0.5)"
                          : "1px solid rgba(15, 23, 43, 0.3)"
                      }
                      value={extras.extraType}
                      onChange={(e) =>
                        setExtras({
                          ...extras,
                          extraType: e.target.value,
                        })
                      }
                    >
                      {extraType?.map((dat, i) => (
                        <option key={i} value={dat?.value}>
                          {dat?.name}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                  <Button
                    onClick={handleExtra}
                    mt="10px"
                    fontWeight={400}
                    isLoading={isEXtra}
                    isDisabled={
                      !extras.extraFee || !extras.extraName || !extras.extraType
                    }
                    color="#fff"
                    w="fit-content"
                  >
                    <Text fontSize="14px">Add</Text>
                  </Button>
                </Box>
              )}
              {inputs?.map((input, index) => (
                <Flex
                  key={index}
                  my="10px"
                  align="flex-end"
                  justifyContent="flex-end"
                >
                  <Flex
                    w="fit-content"
                    bg="#fff"
                    fontSize="14px"
                    gap="40px"
                    border="1px solid rgba(15, 23, 43, 0.2)"
                    py="27px"
                    px="24px"
                    borderRadius="10px"
                    boxShadow="10px 10px 30px rgba(0, 0, 0, 0.1)"
                  >
                    <Text>{input.extraName}</Text>
                    <Text fontWeight={600}>₦ {input.extraFee}</Text>
                  </Flex>
                </Flex>
              ))}
            </Box>

            <Flex
              px="30px"
              mb="31px"
              justifyContent="center"
              align="center"
              w="full"
              gap="16px"
            >
              <Button
                bg="unset"
                _hover={{ bg: "unset" }}
                _active={{ bg: "unset" }}
                _focus={{ bg: "unset" }}
                border="1px solid #333333"
                color="#333"
                w="full"
                h="60px"
                onClick={close}
              >
                Cancel
              </Button>
              <Button
                isLoading={loading}
                isDisabled={!result}
                onClick={() => {
                  setLoading(true);
                  sessionStorage.removeItem("extras");
                  setShowExtra(false);
                  setInputs([]);
                  setExtras({
                    ...values,
                    extraFee: "",
                    extraName: "",
                    extraType: "",
                  });
                  setValues({
                    ...values,
                    class: "",
                    fees: "",
                    id: "",
                  });
                  setTimeout(() => {
                    setResult("");
                  }, 2000);
                  setTimeout(() => {
                    onClose();
                    setLoading(false);
                  }, 1000);
                }}
                w="full"
                h="60px"
              >
                {isLoading ? "Updating" : "Save"} Record
              </Button>
            </Flex>
          </Flex>
        </DrawerBody>
      </DrawerContent>
      <SuccessModal isOpen={success} onClose={() => setSuccess(false)} />
    </Drawer>
  );
};

export default AddClassDrawer;
