import { Flex, Heading, Text, useToast } from "@chakra-ui/react";

const BaseAlert = (props) => {
  const { ...style } = props;
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      {...style}
      py={4}
      px={4}
      borderRadius="10px"
      bgColor={`${style.colorscheme}.700`}
    >
      <Heading as="h4" fontSize="md" color="#fff" fontWeight="500">
        {props.title}
      </Heading>
      {props.details ? (
        <Text color={`${style.colorscheme}.100`}>{props.details}</Text>
      ) : null}
    </Flex>
  );
};

export const SuccessAlert = (props) => {
  return <BaseAlert colorscheme="green" {...props} />;
};

export const ErrorAlert = (props) => {
  return <BaseAlert colorscheme="red" {...props} />;
};
export const WarningAlert = (props) => {
  return <BaseAlert colorscheme="orange" {...props} />;
};
export const InfoAlert = (props) => {
  return <BaseAlert colorscheme="blue" {...props} />;
};

// if (!toast.isActive(ids)) {
//   successToast({
//     id: ids,
//     description: "Surcharge updated",
//     position: "top",
//     size: "sm",
//     status: "success",
//     variant: "subtle",
//   });
// }
const useCustomToast = () => {
  const toast = useToast();
  const ids = "create";
  const successToast = (
    successMsg = "Operation successful",
    pos = "top-right"
  ) => {
    if (!toast.isActive(ids)) {
      return toast({
        id: ids,
        position: pos,
        isClosable: true,
        render: () => <SuccessAlert title="Success" details={successMsg} />,
      });
    }
  };
  const errorToast = (errorMessage = "Error occurred", pos = "top-right") => {
    return toast({
      position: pos,
      isClosable: true,
      render: () => <ErrorAlert title="Error" details={errorMessage} />,
    });
  };
  const warningToast = (warningMessage = "Warning", pos = "top-right") => {
    return toast({
      position: pos,
      isClosable: true,
      render: () => <WarningAlert title="Warning" details={warningMessage} />,
    });
  };
  const infoToast = (infoMessage = "Info", pos = "top-right") => {
    return toast({
      position: pos,
      isClosable: true,
      render: () => <InfoAlert title="Info" details={infoMessage} />,
    });
  };
  return {
    successToast,
    errorToast,
    warningToast,
    infoToast,
  };
};

export default useCustomToast;
