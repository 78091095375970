import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  Image,
  Heading,
  Text,
  Button,
} from "@chakra-ui/react";
import { cop, pf } from "../../assets/exports";
import GoBack from "../common/GoBack";
import { trimID } from "../../utils/helper";
import CopyToClipboard from "react-copy-to-clipboard";

const ParentSuccessModal = ({ isOpen, data, parent, onClose }) => {
  const [copy, setCopy] = useState(false);

  const onCopy = () => {
    setCopy(true);
  };

  useEffect(() => {
    setTimeout(() => {
      if (copy) {
        setCopy(false);
      }
    }, 3000);
  }, [copy]);

  const link = `https://www.parent.edupali.com/verify-otp/${data?.data?.confirmationCode}`;

  return (
    <Modal
      isCentered
      trapFocus={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent overflowY="auto" borderRadius="20px" bg="#fff" color="#000">
        <ModalHeader>
          <GoBack title="Close" reload />
        </ModalHeader>

        <ModalBody>
          <Flex justifyContent="center" align="center" flexDir="column">
            <Image src={pf} w="54px" />
            <Heading my={3} fontSize="16px" fontWeight={600}>
              {`${parent.firstName} ${parent.lastName}`}
            </Heading>
            <Text textAlign="center" mb={7} color="#828282" fontSize="13px">
              You have successfully enrolled a parent and their wards. Please
              copy the link provided below and send it to the parent, allowing
              them to complete the enrollment process and select a payment plan.
              <br />
              <br />
              For guardian without internet access, kindly invite them to the
              school premises to complete the process. Guardians will be
              required to add their card, bank account, or make a transfer,
              depending on their preferred payment plan. In the case of split
              payment, the first installment, including the One-Off Extras.
            </Text>

            <Flex
              bg="#E8EEFD"
              rounded="full"
              p="12px"
              mb="47px"
              align="center"
              w="full"
              justifyContent="space-between"
              border="1px solid rgba(36, 99, 235, 0.5)"
            >
              <Text color="blueBg" fontSize="14px">
                {trimID(
                  `https://www.parent.edupali.com/verify-otp/${data?.data?.confirmationCode}`
                )}
              </Text>
              <CopyToClipboard onCopy={onCopy} text={link}>
                <Button
                  color="#0F172B"
                  fontWeight="normsl"
                  bg="white"
                  rounded="full"
                  _hover={{ bg: "white" }}
                  _active={{ bg: "white" }}
                  _focus={{ bg: "white" }}
                  size="sm"
                  gap="10px"
                >
                  {copy ? "Copied!" : "Copy"}
                  <Image w="20px" src={cop} />
                </Button>
              </CopyToClipboard>
            </Flex>
            <Flex align="center" w="full" gap="16px" mb="52px">
              <Button
                bg="unset"
                w="full"
                onClick={onClose}
                border="1px solid #000"
                h="65px"
                color="#000"
                _hover={{ bg: "unset" }}
                _active={{ bg: "unset" }}
                _focus={{ bg: "unset" }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  window.location.reload();
                  sessionStorage.removeItem("inputs");
                }}
                w="full"
                h="65px"
              >
                Continue
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default ParentSuccessModal;
