export const BANK_CODE = 'BANK_CODE';
export const VALIDATE_BANK = 'VALIDATE_BANK';
export const CREATE_SCHOOL_OWNER = 'CREATE_SCHOOL_OWNER';
export const CREATE_SCHOOL = 'CREATE_SCHOOL';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const SEND_EMAIL_TOKEN = 'SEND_EMAIL_TOKEN';
export const LOGIN = 'LOGIN';
export const CREATE_PIN = 'CREATE_PIN';
export const GET_USER = 'GET_USER';
export const GET_SHAREHOLDERS = 'GET_SHAREHOLDERS';
export const GET_PARENT_DISCOUNT = 'GET_PARENT_DISCOUNT';
export const GET_PARENT_DETAILS = 'GET_PARENT_DETAILS';
export const GET_EXTRA = 'GET_EXTRA';
export const GET_FEES = 'GET_FEES';
export const GET_EXTRA_COUNT = 'GET_EXTRA_COUNT';
export const GET_WARD = 'GET_WARD';
export const GET_LOANS = 'GET_LOANS';
export const LOAN_SIX_MONTH = 'LOAN_SIX_MONTH';
export const GET_LOAN_BALANCE = 'GET_LOAN_BALANCE';
export const GET_INV_LINK = 'GET_INV_LINK';
export const GET_BALANCE = 'GET_BALANCE';
export const GET_PLAN = 'GET_PLAN';
export const GET_PLANS = 'GET_PLANS';
export const GET_FLOW = 'GET_FLOW';
export const CREATE_EXTRA = 'CREATE_EXTRA';
export const CREATE_DEF_EXTRA = 'CREATE_DEF_EXTRA';
export const UPDATE_EXTRA = 'UPDATE_EXTRA';
export const UPDATE_DEF_EXTRA = 'UPDATE_DEF_EXTRA';
export const GET_PLAN_FLOW = 'GET_PLAN_FLOW';
export const GET_TRANSACTION = 'GET_TRANSACTION';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_PASS = 'UPDATE_PASS';
export const UPDATE_PIN = 'UPDATE_PIN';
export const UPDATE_LOAN_ELIGIBILITY = 'UPDATE_LOAN_ELIGIBILITY';
export const VERIFY_BVN = 'VERIFY_BVN';
export const CREATE_SHAREHOLDERS = 'CREATE_SHAREHOLDERS';
export const CHANGE_PASS = 'CHANGE_PASS';
export const CHANGE_PIN = 'CHANGE_PIN';
export const VERIFY_PASS = 'VERIFY_PASS';
export const VERIFY_PIN = 'VERIFY_PIN';
export const GET_PARENT = 'GET_PARENT';
export const INV_PARENT = 'INV_PARENT';
export const APPROVE_PARENT = 'APPROVE_PARENT';
export const WITHDRAW = 'WITHDRAW';
export const ADD_SCHOOL = 'ADD_SCHOOL';
export const UDPATE_STUDENT = 'UDPATE_STUDENT';
export const GET_LOAN_DETAILS = 'GET_LOAN_DETAILS';
export const MOVE_LOAN = 'MOVE_LOAN';
export const CREATE_LOAN = 'CREATE_LOAN';
export const GET_LOAN_PROVIDERS = 'GET_LOAN_PROVIDERS';
export const ADD_BANK = 'ADD_BANK';
export const CREATE_PROFILE = 'CREATE_PROFILE';
export const UPDATE_CLASS = 'UPDATE_CLASS';
export const UPDATE_PARENT = 'UPDATE_PARENT';
export const DELETE_CLASS = 'DELETE_CLASS';
export const DELETE_WARD = 'DELETE_WARD';
export const DELETE_PARENT = 'DELETE_PARENT';
export const DELETE_NOTI = 'DELETE_NOTI';
export const UPDATE_BANK = 'UPDATE_BANK';
export const UPLOAD_LOGO = 'UPLOAD_LOGO';
export const DELETE_EXTRA = 'DELETE_EXTRA';
export const DEL__DEF_EXTRA = 'DEL__DEF_EXTRA';
export const DEL_DISCOUNT = 'DEL_DISCOUNT';
export const GIVE_DICOUNT = 'GIVE_DICOUNT';
export const READ_NOTI = 'READ_NOTI';
export const CREATE_SURCHARGE = 'CREATE_SURCHARGE';
export const UPDATE_SURCHARGE = 'UPDATE_SURCHARGE';
export const GET_SURCHARGE = 'GET_SURCHARGE';
export const UPDATE_ENROLL = 'UPDATE_ENROLL';
export const SET_TERM = 'SET_TERM';
export const UPDATE_TERM = 'UPDATE_TERM';
export const GET_SCHOOL = 'GET_SCHOOL';
export const GET_NOTI = 'GET_NOTI';
export const LOAN_HISTORY = 'LOAN_HISTORY';
export const GET_DIVISION = 'GET_DIVISION';
export const GET_DEFAULT_EXTRAS = 'GET_DEFAULT_EXTRAS';
export const GET_TERM = 'GET_TERM';
export const GET_SCHOOL_PARENT = 'GET_SCHOOL_PARENT ';
export const GET_PARENT_PLANS = 'GET_PARENT_PLANS';
export const GET_EXTRA_HISTORY = 'GET_EXTRA_HISTORY';
export const GET_PARENT_PLAN_TRANS = 'GET_PARENT_PLAN_TRANS';
export const GET_TOTAL_FEE = 'GET_TOTAL_FEE';
export const GET_TOTAL_OUTSTANDING = 'GET_TOTAL_OUTSTANDING ';
export const GET_TOTAL_PAID = 'GET_TOTAL_PAID';
export const GET_TOTAL_OVERDUE = 'GET_TOTAL_OVERDUE ';
export const GET_OVERDUE_PARENTS = 'GET_OVERDUE_PARENTS';
export const GET_PARENT_OVERDUE_PLANS = 'GET_PARENT_OVERDUE_PLANS';
export const GET_TOTAL_SCHOOL_FEE = 'GET_TOTAL_SCHOOL_FEE ';
export const GET_PLAN_TOTAL_PAID = ' GET_PLAN_TOTAL_PAID';
export const GET_PLAN_OUTSTANDING = 'GET_PLAN_OUTSTANDING';
export const GET_PLAN_TOTAL_AMOUNT = 'GET_PLAN_TOTAL_AMOUNT';
export const GET_EXPECTED_PAYMENT = 'GET_EXPECTED_PAYMENT';