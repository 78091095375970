import * as Yup from "yup";

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const pValues = {
  password: "",
  confirmPassword: "",
};

export const validatePword = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be more than 8 characters")
    .matches(
      passwordRegex,
      "Must Be More Than 8 Digits And Must Contain One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
});
export const initialValues = {
  email: "",
  password: "",
  confirmPassword: "",
  phone: "",
  firstName: "",
  lastName: "",
};

export const createValues = {
  schoolName: "",
  address: "",
  studentCount: "",
  category: "",
};

export const initParentValues = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
};

export const initValues = {
  email: "",
  password: "",
};

export const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  phone: Yup.string()
    .trim(false)
    .required("Phone number is required")
    .min(11, "Phone number must be 11 digits")
    .max(11, "Phone number cannot exceed 11 digits"),
  password: Yup.string()
    .required("Password required")
    .matches(
      passwordRegex,
      "Must Be More Than 8 Digits And Must Contain One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: Yup.string()
    .required("Password confirmation is required")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
});

export const createSchema = Yup.object().shape({
  schoolName: Yup.string().required("School Name is required"),
  address: Yup.string().required("School Address is required"),
  studentCount: Yup.string().required("Number of Students is required"),
  category: Yup.string().required("School type is required"),
});

export const validateAddParentSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  phone: Yup.string()
    .trim(false)
    .required("Phone number is required")
    .min(11, "Phone number must be 11 digits")
    .max(11, "Phone number cannot exceed 11 digits"),
});

export const validateSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  password: Yup.string()
    .required("Password required")
    .matches(
      passwordRegex,
      "Must Be More Than 8 Digits And Must Contain One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
});

export const passValue = {
  password: "",
  password2: "",
};

export const validatePassword = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be more than 8 characters")
    .matches(
      /^(?=.*[a-z])/,
      "Password must contain at least one lowercase letter"
    )
    .matches(
      /^(?=.*[A-Z])/,
      "Password must contain at least one uppercase letter"
    )
    .matches(/^(?=.*[0-9])/, "Password must contain at least one number"),
  password2: Yup.string()
    .required("Password confirmation is required")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
});
