import React from "react";
import { useRoutes, useLocation } from "react-router-dom";
import { AuthLayout, NonAuthLayout } from "../layout/PageLayout";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "./routes";

const PublicRouteWrapper = () => {
  const routes = useRoutes(PUBLIC_ROUTES);
  return routes;
};

const PrivateRouteWrapper = () => {
  const routes = useRoutes(PRIVATE_ROUTES);
  return routes;
};

const Pages = () => {
  const location = useLocation();
  const user = sessionStorage.getItem("user");
  const verified = sessionStorage.getItem("incomplete");
  const nologin = sessionStorage.getItem("nologin");
  const noschool = sessionStorage.getItem("noschool");

  return user && verified === null && noschool === null && nologin === null ? (
    <AuthLayout>
      <PrivateRouteWrapper key={location.pathname} />
    </AuthLayout>
  ) : user === null ||
    verified === "not verified" ||
    noschool === "not school" ? (
    <NonAuthLayout>
      <PublicRouteWrapper key={location.pathname} />
    </NonAuthLayout>
  ) : (
    <NonAuthLayout>
      <PublicRouteWrapper key={location.pathname} />
    </NonAuthLayout>
  );
};

export default Pages;
