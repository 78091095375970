import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { logo, logout, users } from '../../assets/exports';
import { Image } from '@chakra-ui/image';
import { useLogOut } from '../../utils/helper';
import { IoIosArrowForward } from 'react-icons/io';
import {
  accounts,
  activeStyle,
  general,
  payments,
} from '../../components/common/constants';
import { NavLink } from 'react-router-dom';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/react';
import { WarningIcon } from '../../assets';
import { useGetSchool, useGetUser } from '../../services/query/school';
import useCustomToast from '../../utils/notifications';

const SideDrawer = ({ isOpen, onClose }) => {
  const logOut = useLogOut();

  const { data: school } = useGetSchool();
  const { warningToast } = useCustomToast();
  const { data } = useGetUser();
  const bank = school?.data?.BankAccounts?.length > 0;
  const term = school?.data?.Enrollments?.length > 0;
  const classes = school?.data?.Divisions?.length > 0;

  const isExpired =
    !school?.data?.isEnrollmentActive && school?.data?.completedSetup;
  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent bgColor="red" color="#000">
        <DrawerBody p={0} overflowY="scroll">
          <Flex
            flexDir="column"
            justifyContent="space-between"
            position={'fixed'}
            zIndex="5"
            w="full"
            bg="#fff"
            borderRight="1px solid #E0E0E0"
          >
            <Box h="100vh" overflowY="scroll">
              <Flex
                bg="#fff"
                pos="sticky"
                px="20px"
                pt="35px"
                top="0"
                borderBottom="1px solid #E0E0E0"
                pb="35px"
                justifyContent="space-between"
                align="center"
              >
                <Flex gap="13px" align="center">
                  <Image src={logo} w="140px" h="50px" />
                </Flex>
                <Box px="12px" py="5px" bg="#E8EEFD" rounded="full">
                  <Text fontSize="12px">School Portal</Text>
                </Box>
              </Flex>

              {(isExpired || !school?.data?.completedSetup) && (
                <Box
                  mx="20px"
                  my="30px"
                  bg="semiBlue"
                  py="16px"
                  px="22px"
                  borderRadius="10px"
                >
                  <Flex
                    borderBottom="1px solid #e0e0e0"
                    pb="12px"
                    gap="7px"
                    align="center"
                  >
                    <Text fontSize="14px" fontWeight={500}>
                      PENDING
                    </Text>
                    <Flex
                      align="center"
                      justifyContent="center"
                      bg="#000"
                      rounded="full"
                      fontSize="10px"
                      w="20px"
                      h="20px"
                      color="orangeBg"
                    >
                      {isExpired
                        ? '01'
                        : data?.data?.isPinSet &&
                          !bank &&
                          !term &&
                          !classes &&
                          !school?.data?.completedSetup
                        ? '04'
                        : data?.data?.isPinSet &&
                          bank &&
                          !term &&
                          !classes &&
                          !school?.data?.completedSetup
                        ? '03'
                        : !data?.data?.isPinSet &&
                          !bank &&
                          !term &&
                          !classes &&
                          !school?.data?.completedSetup
                        ? '05'
                        : data?.data?.isPinSet &&
                          bank &&
                          term &&
                          !classes &&
                          !school?.data?.completedSetup
                        ? '02'
                        : data?.data?.isPinSet &&
                          bank &&
                          term &&
                          classes &&
                          !school?.data?.completedSetup}
                    </Flex>
                  </Flex>
                  <Flex mt="20px" justifyContent="space-between" align="center">
                    <Flex gap="12px" align="center">
                      <WarningIcon fill="#E81313" />
                      <Text fontSize="14px" color="#E81313">
                        Complete Setup
                      </Text>
                    </Flex>
                    <IoIosArrowForward color="#666666" />
                  </Flex>

                  <Flex
                    gap="11px"
                    mt="25px"
                    bg="blueBg"
                    px="18px"
                    py="15px"
                    borderRadius="10px"
                  >
                    <Image src={users} />
                    <NavLink to="/complete-setup">
                      <Text fontSize="14px" color="#fff">
                        {' '}
                        Onboard Students
                      </Text>
                    </NavLink>
                  </Flex>
                </Box>
              )}

              <Box mx="20px" mt="30px">
                <Text
                  mb="10px"
                  fontSize="12px"
                  fontWeight={500}
                  color="#4F4F4F"
                >
                  GENERAL
                </Text>
                {general?.map((item, i) => (
                  <Box
                    key={i}
                    onClick={onClose}
                    _hover={{
                      backgroundColor: 'semiBlue',
                      px: '20px',
                      mx: '-20px',
                    }}
                  >
                    <NavLink
                      to={
                        isExpired
                          ? ''
                          : school?.data?.completedSetup
                          ? item.path
                          : ''
                      }
                      onClick={() =>
                        isExpired
                          ? warningToast('Please update term enrollment')
                          : school?.data?.completedSetup
                          ? ''
                          : warningToast('Please Complete Setup')
                      }
                      style={({ isActive }) =>
                        isActive
                          ? { ...activeStyle }
                          : {
                              ...activeStyle,
                              backgroundColor: '',
                              fontWeight: 400,
                              borderRight: '',
                            }
                      }
                    >
                      <Flex>
                        <Box w="16px" h="16px" mr="11px">
                          {location.pathname === item.path
                            ? item.sec
                            : item.icon}
                        </Box>
                        {item.name}
                      </Flex>
                    </NavLink>
                  </Box>
                ))}
              </Box>

              <Box mt="40px" mx="20px">
                <Text
                  mb="10px"
                  fontSize="12px"
                  fontWeight={500}
                  color="#4F4F4F"
                >
                  PAYMENTS
                </Text>
                {payments?.map((item, i) => (
                  <Box
                    key={i}
                    onClick={onClose}
                    _hover={{
                      backgroundColor: 'semiBlue',
                      px: '20px',
                      mx: '-20px',
                    }}
                  >
                    <NavLink
                      to={
                        isExpired
                          ? ''
                          : school?.data?.completedSetup
                          ? item.path
                          : ''
                      }
                      onClick={() =>
                        isExpired
                          ? warningToast('Please update term enrollment')
                          : school?.data?.completedSetup
                          ? ''
                          : warningToast('Please Complete Setup')
                      }
                      style={({ isActive }) =>
                        isActive
                          ? { ...activeStyle }
                          : {
                              ...activeStyle,
                              backgroundColor: '',
                              fontWeight: 400,
                              borderRight: '',
                            }
                      }
                    >
                      <Flex>
                        <Box w="16px" h="16px" mr="11px">
                          {location.pathname === item.path
                            ? item.sec
                            : item.icon}
                        </Box>
                        {item.name}
                      </Flex>
                    </NavLink>
                  </Box>
                ))}
              </Box>

              <Box mb="80px" mt="40px" mx="20px">
                <Text
                  mb="10px"
                  fontSize="12px"
                  fontWeight={500}
                  color="#4F4F4F"
                >
                  ACCOUNT
                </Text>
                {accounts?.map((item, i) => (
                  <Box
                    key={i}
                    onClick={onClose}
                    _hover={{
                      backgroundColor: 'semiBlue',
                      px: '20px',
                      mx: '-20px',
                    }}
                  >
                    <NavLink
                      to={
                        isExpired
                          ? ''
                          : school?.data?.completedSetup
                          ? item.path
                          : ''
                      }
                      onClick={() =>
                        isExpired
                          ? warningToast('Please update term enrollment')
                          : school?.data?.completedSetup
                          ? ''
                          : warningToast('Please Complete Setup')
                      }
                      style={({ isActive }) =>
                        isActive
                          ? { ...activeStyle }
                          : {
                              ...activeStyle,
                              backgroundColor: '',
                              fontWeight: 400,
                              borderRight: '',
                            }
                      }
                    >
                      <Flex>
                        <Box w="16px" h="16px" mr="11px">
                          {location.pathname === item.path
                            ? item.sec
                            : item.icon}
                        </Box>
                        {item.name}
                      </Flex>
                    </NavLink>
                  </Box>
                ))}
              </Box>

              <Flex
                fontSize="14px"
                fontWeight={400}
                cursor="pointer"
                color="#E81313"
                onClick={logOut}
                align="center"
                gap="11px"
                pb="110px"
                mx="25px"
              >
                <Image src={logout} />
                Log Out
              </Flex>
            </Box>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default React.memo(SideDrawer);
