import React from "react";
import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import GoBack from "../common/GoBack";
import { formatDateTime } from "../../utils/helper";

const NotiDetails = ({ isOpen, onClose, data }) => {
  return (
    <Modal
      isCentered
      trapFocus={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent
        pb="40px"
        overflowY="auto"
        borderRadius="20px"
        bg="#fff"
        color="#000"
      >
        <ModalHeader>
          <Flex
            pb="20px"
            borderBottom="1px solid #e0e0e0"
            justifyContent="space-between"
          >
            <Text fontSize="16px">{formatDateTime(data?.createdAt)}</Text>
            <GoBack onClose={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Text fontWeight={500} fontSize="14px" color="#000">
            {data?.title}
          </Text>
          <Text mt="4px" fontSize="13px" color="#bdbdbd">
            {data?.body}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NotiDetails;
