import React, { useEffect, useState } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Box,
  Flex,
  Text,
  Image,
  Button,
  GridItem,
  RadioGroup,
  Radio,
  Grid,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { pf } from "../../assets/exports";
import { AiOutlineClose } from "react-icons/ai";
import CustomInput from "../common/CustomInput";
import { IoIosArrowDown, IoMdAdd } from "react-icons/io";
import { MdClose } from "react-icons/md";
import {
  useAddStudent,
  useGetAllDivisions,
  useGetDefaultExtras,
  useGetExtras,
  useGetStudent,
  useInviteParent,
} from "../../services/query/school";
import useCustomToast from "../../utils/notifications";
import ParentSuccessModal from "./ParentSuccessModal";
import jwt_decode from "jwt-decode";
import ExtraList from "../common/ExtraList";
import PhoneInput from "../common/PhoneInput";
import {
  initParentValues,
  validateAddParentSchema,
} from "../../utils/validation";
import { Form, Formik } from "formik";

const AddParentDrawer = ({ isOpen, refetchSchool, onClose, refetch }) => {
  const [success, setSuccess] = useState(false);
  const [show, setShow] = useState(false);
  const [div, setDiv] = useState("");
  const [showClass, setShowClass] = useState(false);
  const [parent, setParent] = useState({
    firstName: "",
    lastName: "",
    num: "",
    email: "",
  });
  const [fees, setFees] = useState("");
  const [classes, setClasses] = useState("");
  const id = JSON.parse(sessionStorage.getItem("user"));
  const { data: divisions } = useGetAllDivisions(id?.user?.School?.id);

  const {
    mutate: extraMutate,
    data: extraList,
    isLoading: isExtra,
  } = useGetExtras();

  const { refetch: studentRefetch } = useGetStudent();

  const [inputs, setInputs] = useState([]);
  const [newInput, setNewInput] = useState({
    firstName: "",
    lastName: "",
    term: "",
    class: "",
    fees: "",
    extras: [],
  });
  const modifiedExtras = newInput.extras.map((extra) => ({
    name: extra.name,
    fee: extra.fee,
    type: extra.type,
    status: extra.status,
  }));

  useEffect(() => {
    const storedInputs = JSON.parse(sessionStorage.getItem("inputs"));
    if (storedInputs) {
      setInputs(storedInputs);
    }
  }, []);

  const totalFees =
    inputs?.length > 0 && inputs?.reduce((acc, curr) => acc + curr.fees, 0);

  useEffect(() => {
    sessionStorage.setItem("inputs", JSON.stringify(inputs));
  }, [inputs]);

  const handleAddInput = () => {
    setFees("");
    setClasses("");
    setInputs([...inputs, newInput]);
    setExtras([]);
    setNewInput({
      firstName: "",
      lastName: "",
      term: "",
      class: "",
      fees: "",
      extras: [],
    });
  };
  const [parentData, setParentData] = useState("");
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewInput((prevInput) => ({ ...prevInput, [name]: value }));
  };

  const { errorToast, successToast } = useCustomToast();

  const { mutate, data, isLoading } = useInviteParent({
    onSuccess: (res) => {
      setParentData(res);
      refetch();
      refetchSchool();
      successToast(res?.message);
    },
    onError: (res) => {
      errorToast(
        res?.response?.data?.message || res?.message || "An Error Occured"
      );
    },
  });

  const {
    mutate: studentMutate,
    data: student,
    isLoading: isStudentLoading,
  } = useAddStudent({
    onSuccess: (res) => {
      successToast(res?.message);
      handleAddInput();
      setShow(false);
      setChecks(false);
      studentRefetch();
    },
    onError: (res) => {
      errorToast(
        res?.response?.data?.message || res?.message || "An Error Occured"
      );
    },
  });

  const [decoded, setdecoded] = useState("");

  useEffect(() => {
    if (data) {
      const decoded = jwt_decode(data?.data?.accessToken);
      setdecoded(decoded);
    }
  }, [data]);

  const handleAddStudent = () => {
    studentMutate({
      firstName: newInput.firstName,
      lastName: newInput.lastName,
      divisionId: div,
      isTertiary: false,
      parentId: decoded?.id,
      status: "Pending",
      extras: JSON.stringify(modifiedExtras),
    });
  };

  const [checks, setChecks] = useState(false);

  const handleSubmit = (values = "") => {
    const phoneNumber = `+234${Number(values.phone)}`;
    mutate({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: phoneNumber,
      schoolId: id?.user?.School?.id,
    });
  };

  const { data: defaultExtras } = useGetDefaultExtras();
  const [extras, setExtras] = useState([]);
  const [extralist, setExtralist] = useState("");
  const [showExtra, setShowExtra] = useState(false);
  const totalExtra = extras?.map((item) => item?.fee, 0);
  const totalExtras = totalExtra?.reduce((sum, fee) => sum + fee, 0);
  const [update, setUpdate] = useState(false);

  const handleCheckboxChange = (data) => {
    if (extralist && extralist?.id === data?.id) {
      setExtralist(null);
      setExtras((prevExtras) =>
        prevExtras?.filter((item) => item?.id !== data?.id)
      );
    } else {
      setExtralist(data);
      setExtras((prevExtras) => {
        const existingIndex = prevExtras.findIndex(
          (item) => item.id === data.id
        );
        if (existingIndex >= 0) {
          const newExtras = [...prevExtras];
          newExtras.splice(existingIndex, 1);
          return newExtras;
        } else {
          return [...prevExtras, data];
        }
      });
    }
  };

  useEffect(() => {
    setNewInput({
      ...newInput,
      extras: extras,
    });
  }, [extras]);

  const filteredArr = extras?.filter((item) => item !== "");
  const namesArr = filteredArr.map((item) => item?.name);
  const totalPaid =
    inputs?.length > 0 &&
    inputs?.reduce((acc, item) => {
      if (item?.extras) {
        return acc + item?.extras.reduce((acc2, item2) => acc2 + item2?.fee, 0);
      }
      return acc;
    }, 0);
  const [isHovered, setIsHovered] = useState(false);

  const close = () => {
    onClose();
    setInputs([]);
    setNewInput({
      firstName: "",
      lastName: "",
      term: "",
      class: "",
      fees: "",
      extras: [],
    });
    setParent({
      firstName: "",
      lastName: "",
      num: "",
      email: "",
    });
    setShow(false);
    setChecks(false);
    sessionStorage.removeItem("inputs");
  };
  const details = JSON.parse(sessionStorage.getItem("user"));
  const category = details?.user?.School?.category;

  return (
    <Drawer
      autoFocus={false}
      size="xl"
      isOpen={isOpen}
      placement="right"
      onClose={close}
    >
      <DrawerOverlay />
      <DrawerContent py="20px" bgColor="#fff" color="#000">
        <DrawerBody p={0} overflowY="scroll">
          <Flex flexDirection="column" h="100vh" overflowY="scroll">
            <Flex
              justifyContent="space-between"
              align="center"
              bg="#fff"
              zIndex={2}
              pos="sticky"
              top="0"
              pb="15px"
              px="20px"
              borderBottom="1px solid #E0E0E0"
            >
              <Flex gap="16px" align="center">
                <Image src={pf} w="40px" h="40px" />
                <Text fontWeight={600} fontSize="20px">
                  Enroll Guardian/Wards
                </Text>
              </Flex>
              <Box
                border="1px solid #E0E0E0"
                cursor="pointer"
                _hover={{ boxShadow: "lg" }}
                bg="#f2f2f2"
                onClick={close}
                rounded="full"
                p="2"
              >
                <AiOutlineClose />
              </Box>
            </Flex>

            <Box flex="1" mt="37px" px="30px">
              <Text>Guardian Information</Text>
              <Formik
                onSubmit={handleSubmit}
                initialValues={initParentValues}
                validationSchema={validateAddParentSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isValid,
                  dirty,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Flex flexDir="column" mt="24px" w="full">
                      <Flex
                        align="center"
                        gap="20px"
                        flexDir={{ base: "column", md: "row" }}
                      >
                        <CustomInput
                          opt
                          mb
                          name="firstName"
                          value={values?.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            errors?.firstName &&
                            touched?.firstName &&
                            errors?.firstName
                          }
                          holder="First Name"
                        />

                        <CustomInput
                          opt
                          mb
                          name="lastName"
                          value={values?.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            errors?.lastName &&
                            touched?.lastName &&
                            errors?.lastName
                          }
                          holder="Last Name"
                        />
                      </Flex>
                      <Flex
                        mt={{ base: 5, md: "" }}
                        align="flex-start"
                        flexDir={{ base: "column", md: "row" }}
                        gap="20px"
                      >
                        <Box w="full">
                          <CustomInput
                            opt
                            mb
                            name="email"
                            value={values?.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            holder="Email Address"
                          />
                          <Text
                            mt="8px"
                            fontWeight={500}
                            fontSize="12px"
                            color="#646668"
                          >
                            Email is Optional
                          </Text>
                        </Box>

                        <PhoneInput
                          name="phone"
                          value={`${values?.phone}`}
                          type="number"
                          onChange={(e) => {
                            const inputPhone = e.target.value
                              .replace(/\D/g, "")
                              .slice(0, 11);
                            handleChange({
                              target: {
                                name: "phone",
                                value: `${inputPhone}`,
                              },
                            });
                          }}
                          onBlur={handleBlur}
                          error={
                            errors?.phone && touched?.phone && errors?.phone
                          }
                          placeholder="Phone Number"
                        />
                      </Flex>

                      <Flex
                        mt="15px"
                        justifyContent={"space-between"}
                        flexDir={[
                          "column-reverse",
                          "column-reverse",
                          "row",
                          "row",
                        ]}
                        w="full"
                      >
                        {!inputs.length && !show && (
                          <Flex
                            color="blueBg"
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            pos="relative"
                            className={!inputs.length ? "re_parent" : ""}
                            w="fit-content"
                            onClick={() =>
                              !data
                                ? ""
                                : !show
                                ? setShow(true)
                                : show && handleAddInput()
                            }
                            cursor={!data ? "" : "pointer"}
                            opacity={!data ? 0.6 : ""}
                            _hover={{
                              textDecor: !data ? "" : "underline",
                              textUnderlineOffset: 3,
                            }}
                            mt="20px"
                            align="center"
                            gap="10px"
                          >
                            <Flex align="center" gap="10px" w="fit-content">
                              <IoMdAdd />
                              <Text fontSize="14px">
                                Enroll Guardian's Ward
                              </Text>
                            </Flex>
                            <Text
                              display={!inputs.length ? "block" : "none"}
                              fontWeight={700}
                              fontSize="18px"
                              className="arrows"
                            >
                              ?
                            </Text>

                            <motion.div
                              className="re_content"
                              initial={{ opacity: 0 }}
                              whileInView={{ y: [30, 0], opacity: 1 }}
                            >
                              Click to add a ward to this{" "}
                              {category?.includes("Tertiary")
                                ? "guardian"
                                : "parent"}
                              's account. Please enter accurate information
                              about the ward/student, including their selected
                              class. Additionally, choose the relevant Extras
                              for the ward. The school fees and extra fees will
                              be automatically generated. <br /> <br />
                              If the{" "}
                              {category?.includes("Tertiary")
                                ? "guardian"
                                : "parent"}{" "}
                              has multiple children, kindly repeat the process
                              by clicking on the "Enroll Ward" button below.
                              <br /> <br />
                              Please DO NOT CLOSE or SAVE RECORD until you have
                              added all of the{" "}
                              {category?.includes("Tertiary")
                                ? "guardian"
                                : "parent"}
                              's wards
                            </motion.div>
                          </Flex>
                        )}

                        {data === undefined && (
                          <Button
                            isDisabled={!isValid || !dirty}
                            w={["full", "unset", "unset", "unset"]}
                            h="60px"
                            type="submit"
                            isLoading={isLoading}
                          >
                            Create Guardian Profile
                          </Button>
                        )}
                      </Flex>
                    </Flex>
                  </Form>
                )}
              </Formik>

              {inputs.map((input, index) => (
                <Box w="full" key={index} mb="30px">
                  <Flex my="5px" align="center" justifyContent="space-between">
                    <Text>Ward {index + 1}</Text>
                  </Flex>
                  <Flex
                    w="full"
                    align="center"
                    gap="20px"
                    flexDir={{ base: "column", md: "row" }}
                  >
                    <CustomInput
                      opt
                      value={input.firstName}
                      isDisabled
                      type="text"
                    />
                    <CustomInput
                      opt
                      value={input.lastName}
                      isDisabled
                      type="text"
                    />
                  </Flex>
                  <Flex
                    w="49%"
                    align="center"
                    gap="20px"
                    flexDir={{ base: "column", md: "row" }}
                  >
                    <CustomInput
                      opt
                      value={input.class}
                      isDisabled
                      type="text"
                    />
                  </Flex>

                  <Flex
                    w="full"
                    align="center"
                    gap="20px"
                    justifyContent="space-between"
                    flexDir={{ base: "column", md: "row" }}
                  >
                    <Box w="full">
                      <Text fontWeight={500} mb="5px">
                        Extras
                      </Text>
                      <Flex align="center" gap="10px" flexWrap="wrap">
                        {input.extras?.map((dat, i) => (
                          <Flex
                            bg="#E8EEFD"
                            border="1px solid rgba(36, 99, 235, 0.5)"
                            py="2"
                            px="3"
                            rounded="full"
                            align="center"
                            gap="5px"
                            key={i}
                          >
                            <Text fontWeight={500}>{dat.name} -</Text>
                            <Text color="#333" fontSize="14px">
                              NGN{" "}
                              {dat.fee?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Text>
                          </Flex>
                        ))}
                      </Flex>
                    </Box>
                    <Box w="full" pos="relative">
                      <Text fontSize="25px" fontWeight={700} color="darkBg">
                        ₦{" "}
                        {(
                          input?.fees +
                          input?.extras?.reduce(
                            (sum, extra) => sum + extra.fee,
                            0
                          )
                        )?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                      <Text fontSize="13px" mt="5px" color="#828282">
                        This is the total amount for the term for{" "}
                        <span style={{ color: "#2463EB" }}>
                          {input?.class} Student
                        </span>
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              ))}
              {show && (
                <>
                  <Text my="30px" fontSize="14px">
                    Ward's Record
                  </Text>
                  <Flex flexDir="column" gap="15px" w="full">
                    <Flex
                      align="center"
                      gap="20px"
                      flexDir={{ base: "column", md: "row" }}
                    >
                      <CustomInput
                        mb
                        name="firstName"
                        opt
                        value={newInput.firstName}
                        onChange={handleInputChange}
                        type="text"
                        holder="Enter First Name"
                      />

                      <CustomInput
                        name="lastName"
                        mb
                        opt
                        value={newInput.lastName}
                        onChange={handleInputChange}
                        type="text"
                        holder="Enter Last Name"
                      />
                    </Flex>

                    <Flex
                      align="center"
                      gap="20px"
                      flexDir={{ base: "column", md: "row" }}
                    >
                      <Box pos="relative" w="full">
                        <Flex
                          bg={newInput.class ? "#E8EEFD" : ""}
                          border={
                            newInput.class
                              ? "1px solid rgba(36, 99, 235, 0.5)"
                              : "1px solid rgba(15, 23, 43, 0.3)"
                          }
                          borderRadius="10px"
                          h="60px"
                          cursor="pointer"
                          onClick={() => setShowClass((prev) => !prev)}
                          _hover={{ boxShadow: "lg" }}
                          px="13px"
                          align="center"
                          justifyContent="space-between"
                        >
                          <Text
                            color="#4F4F4F"
                            fontSize="14px"
                            opacity={newInput.class ? 1 : 0.8}
                          >
                            {newInput.class || "Select Class"}
                          </Text>
                          <IoIosArrowDown />
                        </Flex>
                        {showClass && (
                          <Box
                            pos="absolute"
                            border="1px solid #E0E0E0"
                            bg="#fff"
                            w="full"
                            zIndex="2"
                            borderRadius="10px"
                            h="fit"
                            mt="15px"
                            overflowY="scroll"
                          >
                            <Text
                              px="15px"
                              fontSize="13px"
                              color="#4F4F4F"
                              lineHeight="55px"
                            >
                              Choose a current class
                            </Text>
                            <Grid
                              h="15vh"
                              mb="20px"
                              overflowY="scroll"
                              p="15px"
                              overflowX="hidden"
                              templateColumns="repeat(2,1fr)"
                            >
                              {divisions?.data?.map((data, i) => (
                                <GridItem key={i} colSpan={1}>
                                  <RadioGroup
                                    cursor="pointer"
                                    display="flex"
                                    gap="10px"
                                    key={i}
                                    value={newInput.class}
                                    onClick={() => {
                                      setNewInput({
                                        ...newInput,
                                        class: data?.name,
                                        fees: data?.fees,
                                      });
                                      setFees(data?.fees);
                                      setClasses(data?.name);
                                      setDiv(data?.id);
                                      setExtras([]);
                                      extraMutate(data?.id);
                                      setShowClass(false);
                                      setChecks(true);
                                    }}
                                  >
                                    <Radio
                                      bg="semiBlue"
                                      border="1px solid rgba(36, 99, 235, 0.3)"
                                      value={data?.name}
                                    />
                                    <Text fontSize="13px">{data?.name}</Text>
                                  </RadioGroup>
                                </GridItem>
                              ))}
                            </Grid>
                          </Box>
                        )}
                      </Box>

                      <Box w="full">
                        <ExtraList
                          update={update}
                          setUpdate={setUpdate}
                          opt
                          handleCheckboxChange={handleCheckboxChange}
                          list={extraList?.data?.concat(defaultExtras?.data)}
                          showExtra={showExtra}
                          isExtraLoading={isExtra}
                          extras={extras}
                          classes={classes}
                          setShowExtra={setShowExtra}
                          namesArr={namesArr}
                          filteredArr={filteredArr}
                        />
                      </Box>
                    </Flex>

                    <Flex
                      align="flex-start"
                      gap="20px"
                      flexDir={{ base: "column", md: "row" }}
                    >
                      <Box w="full">
                        <Flex
                          mb="40px"
                          align="center"
                          gap="10px"
                          flexWrap="wrap"
                        >
                          {filteredArr?.map((dat, i) => (
                            <Flex
                              bg="#E8EEFD"
                              key={i}
                              border="1px solid rgba(36, 99, 235, 0.5)"
                              py="2"
                              px="3"
                              rounded="full"
                              gap="8px"
                              align="center"
                            >
                              <Flex align="center" gap="5px">
                                <Text fontWeight={500}>{dat?.name} -</Text>
                                <Text color="#333" fontSize="14px">
                                  NGN{" "}
                                  {dat?.fee?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </Text>
                              </Flex>
                              <Flex
                                cursor="pointer"
                                onClick={() => handleCheckboxChange(dat)}
                              >
                                <MdClose />
                              </Flex>
                            </Flex>
                          ))}
                        </Flex>
                      </Box>
                    </Flex>
                  </Flex>
                </>
              )}

              {checks && (
                <Flex
                  gap="20px"
                  mb="50px"
                  flexDir={{ base: "column", md: "row" }}
                  justifyContent="space-between"
                  align="flex-start"
                >
                  <Box
                    w="full"
                    pos="relative"
                    pb={{ base: "20px", md: "42px" }}
                  >
                    <Text fontSize="25px" fontWeight={700} color="darkBg">
                      ₦{" "}
                      {fees?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Text>
                    <Text fontSize="13px" mt="5px" color="#828282">
                      This is the total amount for the term for{" "}
                      <span style={{ color: "#2463EB" }}>
                        {classes} Student
                      </span>
                    </Text>
                    <Flex>
                      <Button
                        mt="20px"
                        isLoading={isStudentLoading}
                        onClick={() => {
                          handleAddStudent();
                        }}
                      >
                        Create Ward
                      </Button>
                    </Flex>
                  </Box>

                  <Box w="full">
                    <Flex
                      bg="#E8EEFD"
                      p="10px"
                      py="15px"
                      borderRadius="10px"
                      border="1px solid rgba(36, 99, 235, 0.5)"
                      flexDir="column"
                      justifyContent="center"
                      align="center"
                    >
                      <Text fontSize="13px" color="blueBg">
                        Total Amount
                      </Text>
                      <Text fontSize="25px" fontWeight={700} color="darkBg">
                        ₦{" "}
                        {totalFees === false
                          ? (totalExtras + fees)?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : Number(
                              totalPaid + totalFees + totalExtras + fees
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Text>

                      <Text fontSize="12px" textAlign="center" color="#828282">
                        This is the Total amount for the term <br /> for all
                        ward's added
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
              )}

              {inputs?.length
                ? !show && (
                    <Flex
                      color="blueBg"
                      pos="relative"
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      className={!inputs.length ? "re_parent" : ""}
                      w="fit-content"
                      onClick={() =>
                        !data
                          ? ""
                          : !show
                          ? setShow(true)
                          : show && handleAddInput()
                      }
                      cursor={!data ? "" : "pointer"}
                      opacity={!data ? 0.6 : ""}
                      _hover={{
                        textDecor: !data ? "" : "underline",
                        textUnderlineOffset: 3,
                      }}
                      mt="20px"
                      align="center"
                      gap="10px"
                    >
                      <Flex align="center" gap="10px" w="fit-content">
                        <IoMdAdd />
                        <Text fontSize="14px">Enroll Guardian's Ward</Text>
                      </Flex>
                      <Text
                        display={!inputs.length ? "block" : "none"}
                        fontWeight={700}
                        fontSize="18px"
                        className="arrows"
                      >
                        ?
                      </Text>

                      <motion.div
                        className="re_content"
                        initial={{ opacity: 0 }}
                        whileInView={{ y: [30, 0], opacity: 1 }}
                      >
                        Click to add a ward to this parent's account. Please
                        enter accurate information about the ward/student,
                        including their selected class. Additionally, choose the
                        relevant Extras for the ward. The school fees and extra
                        fees will be automatically generated. <br /> <br />
                        If the parent has multiple children, kindly repeat the
                        process by clicking on the "Enroll Ward" button below.
                        <br /> <br />
                        Please DO NOT CLOSE or SAVE RECORD until you have added
                        all of the parent's wards
                      </motion.div>
                    </Flex>
                  )
                : ""}
            </Box>
            <Flex
              mb={{ base: "101px", md: "31px" }}
              className="btn_hover"
              mt={{
                base: isHovered ? "320px" : "30px",
                md: isHovered ? "200px" : "30px",
              }}
              px="22px"
              justifyContent="center"
              align="center"
              gap="16px"
            >
              <Button
                bg="unset"
                _hover={{ bg: "unset" }}
                _active={{ bg: "unset" }}
                _focus={{ bg: "unset" }}
                border="1px solid #333333"
                color="#333"
                w="216px"
                h="60px"
                onClick={close}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setSuccess(true);
                  sessionStorage.removeItem("inputs");
                }}
                isDisabled={!data || !student || !inputs?.length}
                w="216px"
                h="60px"
              >
                Save Record
              </Button>
            </Flex>
          </Flex>
        </DrawerBody>
      </DrawerContent>
      <ParentSuccessModal
        data={parentData}
        parent={parent}
        isOpen={success}
        onClose={() => setSuccess(false)}
      />
    </Drawer>
  );
};

export default AddParentDrawer;
