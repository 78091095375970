export const PUBLIC_PATHS = {
  LOGIN: '/login',
  REG_1: '/register-step-1',
  REG_2: '/register-step-2',
  FOG_PASS: '/forgot-password',
  VER_PASS: '/code-verification',
  CH_PASS: '/change-password',
};

export const PRIVATE_PATHS = {
  DASHBOARD: '/dashboard',
  SETTINGS: '/settings',
  NOTI: '/notifications',
  AUDIT: '/audit-log',
  TRAN_LOG: '/transaction-log',
  PAR_MGT: '/guardian-mgt',
  LOAN: '/loan-facility',
  PORTAL: '/school-portal',
  EXTRAS: '/school-portal/:schoolId/:division/:id/extras',
  SETUP: '/complete-setup',
  ROLES: '/roles',
  TEAMS: '/team-mgt',
};
