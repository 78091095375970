import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  Image,
  Heading,
  Text,
  Button,
} from "@chakra-ui/react";
import { warn } from "../../assets/exports";
import GoBack from "../common/GoBack";
import SuccessModal from "./SuccessModal";

const DeleteNoti = ({ isOpen, onClose, isLoading, action }) => {
  const [show, setShow] = useState(false);

  return (
    <Modal
      isCentered
      trapFocus={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent overflowY="auto" borderRadius="20px" bg="#fff" color="#000">
        <ModalHeader>
          <Flex
            pb="20px"
            borderBottom="1px solid #e0e0e0"
            justifyContent="space-between"
          >
            <Text>Confirm Request</Text>
            <GoBack onClose={onClose} />
          </Flex>
        </ModalHeader>

        <ModalBody>
          <Flex
            mt="21px"
            justifyContent="center"
            align="center"
            flexDir="column"
          >
            <Image src={warn} w="144px" />
            <Heading my={3} fontSize="24px" color="#EB5757" fontWeight={700}>
              Delete notification
            </Heading>
            <Text textAlign="center" mb={7} color="#828282" fontSize="16px">
              This action will delete this notification and will not be
              reversible
            </Text>

            <Flex w="full" mb="36px" gap="12px" align="center">
              <Button
                w="full"
                h="61px"
                border="1px solid #333333"
                color="#000"
                bg="unset"
                onClick={onClose}
              >
                No, Cancel
              </Button>
              <Button isLoading={isLoading} w="full" h="61px" onClick={action}>
                Yes, Delete
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
      <SuccessModal del isOpen={show} onClose={() => setShow(false)} />
    </Modal>
  );
};
export default DeleteNoti;
