import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";
import { check } from "../../assets/exports";

const AuthBase = () => {
  return (
    <Flex fontSize="16px" gap="31px" justifyContent="center" align="center">
      <Image src={check} w="32px" h="32px" />
      <Text>Secure and Reliable</Text>
    </Flex>
  );
};

export default AuthBase;
