export const VERIFY_ACC = (accountNumber = "", bankCode = "") =>
  `banks/resolveBankName?recipientAccountNumber=${accountNumber}&bankCode=${bankCode}`;
export const CREATE_SCHOOL_OWNER =
  process.env.REACT_APP_BASE_URL + "create-school-owner";

export const VERIFY_EMAIL =
  process.env.REACT_APP_BASE_URL + "users/updateEmail";
export const SEND_EMAIL_TOKEN =
  process.env.REACT_APP_BASE_URL + "users/sendEmailToken";
export const LOGIN = process.env.REACT_APP_BASE_URL + "auth/school/signin";
export const GET_USER = "users/getUserData";
export const CREATE_SCHOOL =
  process.env.REACT_APP_BASE_URL + "school/createSchool";
export const CREATE_PIN = "users/create-pin-school-owner";
export const GET_SCHOOL = "get-school";
export const UPDATE_LOAN_ELIGIBILITY = "update-loan-eligibity";
export const CREATE_SHAREHOLDERS = "add-share-holder";
export const VERIFY_BVN = (bvn = "") => `get-bvn-details?bvn=${bvn}`;
export const BANK_CODE = "banks/getBanks";
export const INV_PARENT = "school/invite-parent";
export const ADD_SCHOOL = "school/create-student";
export const UDPATE_STUDENT = "school/update-student";
export const UPLOAD_LOGO = "upload-school-logo";
export const GET_LOAN_PROVIDERS = "get-loan-providers-for-school";
export const GET_LOANS = "get-loans";
export const GET_LOAN_DETAILS = (id = "") => `get-loan-detail/${id}`;
export const GET_LOAN_BALANCE = "loan-balance";
export const MOVE_LOAN = "move-loan-balance";
export const CREATE_LOAN = "create-loan";
export const UPDATE_BANK = "update-school-bank-account";
export const LOAN_HISTORY = "get-school-loan-history";
export const UPDATE_USER = "update-school-owner";
export const LOAN_SIX_MONTH = "get-loans-last-six-month/?status=Active";
export const UPDATE_PASS = "password-reset-owner";
export const SEND_LOGIN_PASS_OTP = "password-reset-owner-on-login";
export const VERIFY_LOGIN_OTP = "verify-reset-owner-on-login";
export const UPDATE_PIN = "pin-reset-owner";
export const CHANGE_PASS = "change-school-owner-password";
export const CHANGE_PIN = "change-pin-school-owner";
export const VERIFY_PASS = "verify-reset-owner";
export const VERIFY_PIN = "verify-pin-reset-owner";
export const GET_PLAN = "get-plans-by-type";
export const GET_PLANS = "get-plans-status?status=Active";
export const GET_BALANCE = "get-school-wallet";
export const GET_WARD = "school/get-students";
export const ADD_BANK = "create-school-bank-account";
export const CREATE_EXTRA = "create-extra";
export const CREATE_DEF_EXTRA = "create-default-extra";
export const GET_EXTRA = "get-extras";
export const GET_EXTRAS = (id = "") => `get-extras?divisionId=${id}`;
export const UPDATE_EXTRA = "update-extra";
export const UPDATE_DEF_EXTRA = "update-default-extra";
export const GET_FEES = (id = "", session = "") =>
  `get-school-plans-aggregates?enrollmentId=${id}&session=${session}`;
export const SET_TERM = "create-enrollment";
export const SET_NEW_TERM = "create-new-enrollment";
export const UPDATE_TERM = "update-enrollment-details";
export const UPDATE_ENROLL = "update-enrollment-details";
export const CREATE_PROFILE = "create-division";
export const CREATE_DEPT = "school/create-department";
export const GET_DEPTS = "school/get-all-departments?page=1";
export const CREATE_LEVEL = "school/create-level";
export const UPDATE_CLASS = "update-division-and-plan";
export const GET_TRANSACTION = "get-transaction-history";
export const GET_DIVISION = "get-divisions";
export const GET_DEFAULT_EXTRAS = "get-school-default-extras";
export const DEL_ENROLLMENT = (id = "") => `delete-enrollment/${id}`;
export const DEL_DISCOUNT = (id = "") => `delete-discount-history/${id}`;
export const CREATE_SURCHARGE = "create-surcharge";
export const UPDATE_SURCHARGE = "update-surcharge";
export const GET_SURCHARGE = "get-surcharge-by-plan";
export const GET_TERM = "get-enrollments";
export const WITHDRAW = "withdraw-school-owner";
export const GET_FLOW = "activity-performance";
export const GET_PLAN_FLOW = "get-total-flow";
export const GTE_EXTRA_COUNT = "get-students-count-extra";
export const GET_PARENT = "school/get-parents-by-status";
export const GET_PARENT_COUNT = "school/get-parents-count-by-status";
export const GET_PARENT_DETAILS = (id = "") => `school/get-parent/${id}`;
export const GET_PARENT_OVERDUE_DETAILS = (id = "") =>
  `school/parent-overdue/${id}`;
export const GIVE_DICOUNT = "create-discount-history";
export const GET_PARENT_DISCOUNT = "get-parent-discount-history";
export const GET_NOTI = "get-notifications?isOrg=true";
export const READ_NOTI = "update-notification-status";
export const DELETE_NOTI = (id = "") => `delete-notification/${id}`;
export const DEL_EXTRA = (id = "") => `delete-extra/${id}`;
export const DEL__DEF_EXTRA = (id = "") => `delete-default-extra/${id}`;
export const DELETE_CLASS = (id = "") => `delete-division/${id}`;
export const DELETE_WARD = (id = "") => `delete-student/${id}`;
export const DELETE_PARENT = "delete-parent";
export const UPDATE_PARENT = "update-parent";
export const GET_SHAREHOLDERS = "get-school-shareholders";
export const APPROVE_PARENT = "approve-parent-for-enrollment";
export const GET_INV_LINK = "get-invite-link";
export const PAR_PLAN_TRAN = (id = "") =>
  `school/get-parent-plan-transactions?parentId=${id}&page=1`;
export const PAR_EXTRA = (id = "", enrollmentId = "") =>
  `school/get-parent-ward-extra-history/${id}?page=1&enrollmentId=${enrollmentId}`;
export const GET_TOTAL_FEE = "school/total-school-fee";

export const GET_TOTAL_OUTSTANDING = "school/total-outstanding";

export const GET_TOTAL_PAID = "school/total-paid";

export const GET_TOTAL_OVERDUE = "school/total-overdue";
export const GET_OVERDUE_PARENTS = "school/parents-with-overdue";
export const GET_PARENT_OVERDUE_PLANS = (id = "") =>
  `school/get-parent-overdue-plans/${id}`;

export const ADD_WARD_EXTRA = "school/add-extras";
export const REMOVE_WARD_EXTRA = "school/remove-extra";
export const GET_ADMINS = 'school/get-staff';
export const GET_PERMISSIONS = 'school/get-permissions';
export const GET_ROLES = 'school/get-roles';
export const DEL_ROLE = (id = '') => `school/delete-role/${id}`;
export const DEL_STAFF = (id = '') => `school/delete-staff/${id}`;
export const EDIT_ROLES = 'school/edit-role';
export const CREATE_ROLES = 'school/create-role';
export const INV_ADMIN = 'school/invite-staff';
export const UPDATE_ADMIN = (id = '') => `school/update-staff/${id}`;
export const GET_TOTAL_SCHOOL_FEE = 'school/total-school-fee';
export const GET_PLAN_TOTAL_PAID = (id = '') =>
  `school/get-plan-total-paid/${id}`;
export const GET_PLAN_OUTSTANDING = (id = '') =>
  `school/get-plan-outstanding/${id}`;
export const GET_PLAN_TOTAL_AMOUNT = (id = '') =>
  `school/get-plan-total-amount/${id}`;
export const GET_EXPECTED_PAYMENT = (id = '') =>
  `school/get-parent-expected-payment/${id}`;