import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  Image,
  Heading,
  Text,
} from "@chakra-ui/react";
import { success } from "../../assets/exports";
import GoBack from "../common/GoBack";
import AddClassDrawer from "./AddClassDrawer";
import AddParentDrawer from "./AddParentDrawer";

const SuccessModal = ({
  isOpen,
  bank,
  successMsg,
  print,
  extra,
  newEx,
  del,
  reset,
  upd,
  mail,
  classes,
  wallet,
  pin,
  onClose,
  ward,
}) => {
  const [show, setShow] = useState(false);
  const [showParent, setShowParent] = useState(false);
  return (
    <Modal
      isCentered
      trapFocus={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent overflowY="auto" borderRadius="20px" bg="#fff" color="#000">
        <ModalHeader>
          <GoBack title="Close" reload />
        </ModalHeader>

        <ModalBody>
          <Flex my={10} justifyContent="center" align="center" flexDir="column">
            <Image src={success} w="144px" />
            <Heading my={3} fontSize="24px" fontWeight={700}>
              Awesome!
            </Heading>
            <Text textAlign="center" mb={7} color="#828282" fontSize="16px">
              {ward
                ? "You have successfully saved a Guardian record to your portal."
                : classes
                ? `You have successfully updated ${classes} record`
                : mail
                ? `Your profile information has been successfully updated`
                : del
                ? `You have successfully deleted the class record`
                : print
                ? `Your Statement result has been sent to your registered email address`
                : bank
                ? `You have successfully updated your withdrawal account`
                : wallet
                ? `Your withdrawal request was successful`
                : reset
                ? `You have successfully updated your password`
                : upd
                ? `You have successfully updated this class record`
                : extra
                ? `You have successfully updated this record`
                : newEx
                ? successMsg
                : pin
                ? `You have successfully updated your transaction PIN`
                : "You have successfully saved a school record to your portal"}
            </Text>
          </Flex>
        </ModalBody>
      </ModalContent>

      <AddClassDrawer isOpen={show} onClose={() => setShow(false)} />
      <AddParentDrawer
        isOpen={showParent}
        onClose={() => setShowParent(false)}
      />
    </Modal>
  );
};
export default SuccessModal;
