import React from "react";
import {
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const CustomInput = ({
  value,
  opt,
  onClick,
  show,
  password,
  name,
  isDisabled,
  handleKeyPress,
  error,
  onChange,
  mb,
  dis,
  holder,
  type,
}) => {
  return (
    <FormControl mb={mb ? 0 : 5} isInvalid={error}>
      <InputGroup
        width="full"
        color={error ? "red" : value ? "#7B47CC" : "#BDBDBD"}
      >
        <Input
          value={value}
          isDisabled={dis}
          name={name}
          isReadOnly={isDisabled}
          onKeyPress={handleKeyPress}
          onChange={onChange}
          bg={value ? "#E8EEFD" : ""}
          border={
            error
              ? "1px solid red"
              : value
              ? "1px solid rgba(36, 99, 235, 0.5)"
              : "1px solid rgba(15, 23, 43, 0.3)"
          }
          h={opt ? "60px" : "50px"}
          type={type ? type : "text"}
          variant="secondary"
          _placeholder={{ fontSize: "13px" }}
          placeholder={holder}
        />
        <InputRightElement
          cursor="pointer"
          h="60px"
          display={show ? "flex" : "none"}
          onClick={onClick}
        >
          {!password ? (
            <FaEyeSlash color={value ? "#0F172B" : "#4F4F4F"} />
          ) : (
            <FaEye color={value ? "#0F172B" : "#4F4F4F"} />
          )}
        </InputRightElement>
      </InputGroup>
      {error && (
        <Text fontSize="12px" color="red">
          {error}
        </Text>
      )}
    </FormControl>
  );
};

export default CustomInput;
