import React from 'react';
import { Box, Flex, Image, Text, useMediaQuery } from '@chakra-ui/react';
import SideBar from './AuthLayout/SideBar';
import SideBarr from './NonAuthLayout/SideBarr';
import Header from './AuthLayout/Header';
import { logo } from '../assets/exports';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthBase from '../components/common/AuthBase';
import { useGetSchool, useGetUser } from '../services/query/school';
import { useEffect } from 'react';

export const AuthLayout = ({ children }) => {
  const [isMobile] = useMediaQuery('(max-width: 1226px)');
  const location = useLocation();
  const navigate = useNavigate();
  const { data: school } = useGetSchool();
  const { data: userData } = useGetUser();
  const isExpired =
    !school?.data?.isEnrollmentActive && school?.data?.completedSetup;

  useEffect(() => {
    if (isExpired) {
      navigate('/complete-setup');
    }
  }, [school]);

  useEffect(() => {
    if (userData) {
      localStorage.setItem('email', userData?.data?.email);
    }
  }, [userData]);

  useEffect(() => {
    const events = [
      'mousedown',
      'mousemove',
      'keydown',
      'scroll',
      'touchstart',
    ];
    const resetIdleTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        sessionStorage.clear();
        window.location.href = '/login';
      }, 420000);
    };
    events.forEach((event) => window.addEventListener(event, resetIdleTimer));

    let timeoutId = setTimeout(() => {
      sessionStorage.clear();
      window.location.href = '/login';
    }, 420000);

    return () => {
      clearTimeout(timeoutId);
      events.forEach((event) =>
        window.removeEventListener(event, resetIdleTimer)
      );
    };
  }, []);

  return (
    <Box
      overflowX={isMobile ? 'hidden' : ''}
      color="#000"
      fontFamily="Poppins"
      bg="#fafafa"
      position="relative"
    >
      <Box minH="100vh" overflowX="hidden">
        <Header />
        {!isMobile && <SideBar />}
        <Box
          w="100%"
          pt={
            location.pathname === '/dashboard'
              ? '125px'
              : isMobile
              ? '180px'
              : '125px'
          }
          minH="100vh"
          color="#828282"
          pl={!isMobile ? '320px' : '20px'}
          pr={!isMobile ? '50px' : '20px'}
          pb={10}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export const NonAuthLayout = ({ children }) => {
  const [isMobile] = useMediaQuery('(max-width: 1226px)');
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Box color="#000" fontFamily="Poppins" bg="#fff" position="relative">
      <Flex minH="100vh">
        <Flex pb="30px" flexDir="column" w={!isMobile ? '50%' : '100%'}>
          <Box bg="#fff" pos="sticky" top="0" py="20px" px="38px">
            <Flex justifyContent="space-between" align="center">
              <Flex gap="13px" align="center">
                <Image src={logo} w="140px" h="50px" />
              </Flex>
              {location.pathname?.includes('/register') && (
                <Box px="12px" py="5px" bg="#E8EEFD" rounded="full">
                  <Text fontSize="13px" fontWeight={500}>
                    STEP {location.pathname?.includes('1') ? '1/2' : '2/2'}
                  </Text>
                </Box>
              )}
            </Flex>
          </Box>
          <Box
            flex="1"
            pt={!isMobile ? '0px' : 10}
            px={!isMobile ? '80px' : 10}
          >
            {children}
            {(location.pathname?.includes('/register') ||
              location.pathname?.includes('/login')) && (
              <Flex
                mt="15px"
                mb="50px"
                fontSize="14px"
                gap=".5rem"
                justifyContent="flex-end"
              >
                <Text>
                  {location.pathname?.includes('/login')
                    ? "Don't have"
                    : location.pathname?.includes('/register') && 'Have'}{' '}
                  an account?{' '}
                </Text>
                <Text
                  color="blueBg"
                  fontWeight={500}
                  cursor="pointer"
                  onClick={() =>
                    location.pathname?.includes('/login')
                      ? navigate('/register-step-1')
                      : location.pathname?.includes('/register') &&
                        (navigate('/login'),
                        sessionStorage.removeItem('noschool'),
                        sessionStorage.setItem('nologin', 'nologin'))
                  }
                  _hover={{ textDecor: 'underline' }}
                >
                  {location.pathname?.includes('/login')
                    ? 'Register'
                    : location.pathname?.includes('/register') && 'Login'}
                </Text>
              </Flex>
            )}
          </Box>

          <AuthBase />
        </Flex>
        {!isMobile && <SideBarr />}
      </Flex>
    </Box>
  );
};
