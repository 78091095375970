import React from "react";
import { Box, Flex, Grid, GridItem, Spinner, Text } from "@chakra-ui/react";
import { IoIosArrowDown } from "react-icons/io";
import { MdCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";

const ExtraList = ({
  handleCheckboxChange,
  list,
  showExtra,
  setShowExtra,
  handleAddExtra,
  isAdding,
  update,
  extras,
  filteredArr,
  isExtraLoading,
  setUpdate,
  classes,
  edit,
  opt,
  namesArr,
}) => {
  return (
    <Box mb={opt ? "" : "10px"} pos="relative">
      <Flex
        bg={filteredArr?.length > 0 ? "#E8EEFD" : ""}
        border={"1px solid rgba(15, 23, 43, 0.3)"}
        borderRadius="10px"
        h="60px"
        cursor="pointer"
        onClick={() => {
          isExtraLoading ? "" : setShowExtra((prev) => !prev);
        }}
        _hover={{ boxShadow: "lg" }}
        px="13px"
        align="center"
        justifyContent="space-between"
      >
        <Text color="#4F4F4F" fontSize="14px">
          {filteredArr?.length > 0
            ? namesArr?.join(", ")
            : isExtraLoading
            ? `Checking ${classes} Extras`
            : "Select Extras"}
        </Text>
        {isExtraLoading ? (
          <Spinner thickness="4px" size="md" color="blueBg" />
        ) : (
          <IoIosArrowDown />
        )}
      </Flex>
      {showExtra && (
        <Box
          pos="absolute"
          h="17rem"
          overflowY="scroll"
          w="full"
          border="1px solid #E0E0E0"
          bg="#fff"
          zIndex="2"
          borderRadius="10px"
          mt="5px"
        >
          <Flex
            p="15px 15px 0 0"
            bg="#fff"
            justifyContent="flex-end"
            align="flex-end"
            pos="sticky"
            top="0"
          >
            <Flex
              px="3"
              w="fit-content"
              cursor="pointer"
              onClick={() =>
                edit && update ? handleAddExtra() : setShowExtra(false)
              }
              py="2"
              rounded="full"
              bg="#E8EEFD"
              border="1px solid rgba(36, 99, 235, 0.5)"
            >
              {isAdding ? (
                <Flex align="center" gap="10px">
                  <Text fontSize="15px">Processing</Text>
                  <Spinner size="sm" />{" "}
                </Flex>
              ) : (
                "Process"
              )}
            </Flex>
          </Flex>

          <Grid
            p="15px"
            overflowX="hidden"
            gap="20px"
            templateColumns="repeat(1,1fr)"
          >
            {list?.length > 0 ? (
              list?.map((data, i) => (
                <GridItem key={i} colSpan={1}>
                  <Flex
                    gap="15px"
                    cursor="pointer"
                    onClick={() => {
                      handleCheckboxChange(data);
                      setUpdate(true);
                    }}
                    align="center"
                  >
                    {extras &&
                    extras.some((item) => item.name === data.name) ? (
                      <MdCheckBox color="#2463EB" size="20px" />
                    ) : (
                      <MdOutlineCheckBoxOutlineBlank
                        color="#2463EB"
                        size="20px"
                      />
                    )}
                    <Box>
                      <Text fontWeight={500}>{data.name}</Text>
                      <Text color="#333" fontSize="14px">
                        {data.fees}
                      </Text>
                    </Box>
                  </Flex>
                </GridItem>
              ))
            ) : (
              <Text textAlign="center" fontWeight={500}>
                No Extras has been created for this class
              </Text>
            )}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default ExtraList;