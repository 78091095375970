import React from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Text,
} from "@chakra-ui/react";
import { search } from "../../assets/exports";
import { IoIosArrowDown } from "react-icons/io";

const ClassList = ({
  values,
  lists,
  setShow,
  res,
  show,
  opt,
  filter,
  setValues,
  setFilter,
  allDepts,
  searchBoxRef,
}) => {
  const details = JSON.parse(sessionStorage.getItem("user"));
  const category = details?.user?.School?.category;
  return (
    <Box w="full" pos="relative" className="box">
      <Flex
        bg={values.class ? "#E8EEFD" : ""}
        border={
          values.class
            ? "1px solid rgba(36, 99, 235, 0.5)"
            : "1px solid rgba(15, 23, 43, 0.3)"
        }
        borderRadius="10px"
        h={opt ? "60px" : "50px"}
        cursor={allDepts?.data?.length ? "" : "pointer"}
        onClick={() => (allDepts?.data?.length ? "" : setShow((prev) => !prev))}
        _hover={{ boxShadow: allDepts?.data?.length ? "" : "lg" }}
        px="13px"
        align="center"
        justifyContent="space-between"
      >
        <Text color="#4F4F4F" fontSize="14px" opacity={values.class ? 1 : 0.8}>
          {values.class ||
            res?.data?.name ||
            `Select Student ${
              category?.includes("Tertiary") ? "Dept." : "Class"
            }`}
        </Text>
        <IoIosArrowDown />
      </Flex>
      {show && (
        <Box
          pos="absolute"
          border="1px solid #E0E0E0"
          bg="#fff"
          zIndex="2"
          h="32vh"
          borderRadius="10px"
          mt="15px"
          overflowY="scroll"
        >
          <InputGroup p="15px">
            <InputLeftElement m="15px" h="50px">
              <Image src={search} w="20px" h="20px" />
            </InputLeftElement>
            <Input
              value={filter}
              ref={searchBoxRef}
              h="50px"
              onChange={(e) => setFilter(e.target.value)}
              bg="#F2F2F2"
              placeholder="Search Class"
              _placeholder={{ fontSize: "13px" }}
            />
          </InputGroup>
          <Grid
            h="35vh"
            mb="20px"
            overflowY="scroll"
            p="15px"
            overflowX="hidden"
            gap="5px"
            templateColumns="repeat(2,1fr)"
          >
            {lists?.map((data, i) => (
              <GridItem key={i} colSpan={1}>
                <RadioGroup
                  cursor="pointer"
                  display="flex"
                  gap="10px"
                  key={i}
                  value={values.class}
                  onClick={() => {
                    setValues({
                      ...values,
                      class: data,
                    });
                    setShow(false);
                  }}
                >
                  <Radio
                    bg="semiBlue"
                    border="1px solid rgba(36, 99, 235, 0.3)"
                    value={data}
                  />
                  <Text fontSize="13px">{data}</Text>
                </RadioGroup>
              </GridItem>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default ClassList;
