import axiosInstance, { uploadInstance } from '../axiosInstance';
import * as API from '../url';

export const getStudent = async () => {
  const res = await axiosInstance.get(API.GET_WARD);
  return res.data;
};

export const getDepartments = async () => {
  const res = await axiosInstance.get(API.GET_DEPTS);
  return res.data;
};

export const getLoans = async () => {
  const res = await axiosInstance.get(API.GET_LOANS);
  return res.data;
};

export const getInvLink = async () => {
  const res = await axiosInstance.get(API.GET_INV_LINK);
  return res.data;
};

export const getLoanBalance = async () => {
  const res = await axiosInstance.get(API.GET_LOAN_BALANCE);
  return res.data;
};

export const getLoanSixMonth = async () => {
  const res = await axiosInstance.get(API.LOAN_SIX_MONTH);
  return res.data;
};

export const verifyBvn = async (query) => {
  const res = await axiosInstance.get(API.VERIFY_BVN(query));
  return res.data;
};

export const getParentPlan = async (query) => {
  const res = await axiosInstance.get(API.PAR_PLAN_TRAN(query));
  return res.data;
};

export const inviteParent = async (body) => {
  const res = await axiosInstance.post(API.INV_PARENT, body);
  return res.data;
};

export const updateLoanElig = async (body) => {
  const res = await axiosInstance.put(API.UPDATE_LOAN_ELIGIBILITY, body);
  return res.data;
};

export const approveParent = async (body) => {
  const res = await axiosInstance.put(API.APPROVE_PARENT, body);
  return res.data;
};

export const updateUser = async (body) => {
  const res = await axiosInstance.post(API.UPDATE_USER, body);
  return res.data;
};

export const updatePass = async (body) => {
  const res = await axiosInstance.post(API.UPDATE_PASS, body);
  return res.data;
};

export const updatePin = async (body) => {
  const res = await axiosInstance.post(API.UPDATE_PIN, body);
  return res.data;
};

export const changePin = async (body) => {
  const res = await axiosInstance.put(API.CHANGE_PIN, body);
  return res.data;
};

export const changePass = async (body) => {
  const res = await axiosInstance.put(API.CHANGE_PASS, body);
  return res.data;
};

export const createExtra = async (body) => {
  const res = await axiosInstance.post(API.CREATE_EXTRA, body);
  return res.data;
};

export const createDefExtra = async (body) => {
  const res = await axiosInstance.post(API.CREATE_DEF_EXTRA, body);
  return res.data;
};

export const updateExtra = async (body) => {
  const res = await axiosInstance.put(API.UPDATE_EXTRA, body);
  return res.data;
};

export const updateDefExtra = async (body) => {
  const res = await axiosInstance.put(API.UPDATE_DEF_EXTRA, body);
  return res.data;
};

export const verifyPass = async (body) => {
  const res = await axiosInstance.post(API.VERIFY_PASS, body);
  return res.data;
};

export const createShareholders = async (body) => {
  const res = await uploadInstance.post(API.CREATE_SHAREHOLDERS, body);
  return res.data;
};

export const verifyPin = async (body) => {
  const res = await axiosInstance.post(API.VERIFY_PIN, body);
  return res.data;
};

export const withdraw = async (body) => {
  const res = await axiosInstance.post(API.WITHDRAW, body);
  return res.data;
};

export const addStudent = async (body) => {
  const res = await axiosInstance.post(API.ADD_SCHOOL, body);
  return res.data;
};

export const updateStudent = async (body) => {
  const res = await axiosInstance.put(API.UDPATE_STUDENT, body);
  return res.data;
};

export const updateClass = async (body) => {
  const res = await axiosInstance.post(API.UPDATE_CLASS, body);
  return res.data;
};

export const updateParent = async (body) => {
  const res = await axiosInstance.put(API.UPDATE_PARENT, body);
  return res.data;
};

export const giveDiscount = async (body) => {
  const res = await axiosInstance.post(API.GIVE_DICOUNT, body);
  return res.data;
};

export const getShareholders = async () => {
  const res = await axiosInstance.get(API.GET_SHAREHOLDERS);
  return res.data;
};

export const getParent = async ({ queryKey }) => {
  const [, status, page] = queryKey;
  const res = await axiosInstance.get(
    `${API.GET_PARENT}?status=${status}&page=${page}`
  );
  return res.data;
};

export const getParentCount = async ({ queryKey }) => {
  const [, status] = queryKey;
  const res = await axiosInstance.get(
    `${API.GET_PARENT_COUNT}?status=${status}`
  );
  return res.data;
};

export const getParentDetails = async (query) => {
  const res = await axiosInstance.get(API.GET_PARENT_DETAILS(query));
  return res.data;
};

export const getParentOverdueDetails = async (query) => {
  const res = await axiosInstance.get(API.GET_PARENT_OVERDUE_DETAILS(query));
  return res.data;
};

export const getExtra = async (query) => {
  const res = await axiosInstance.get(API.GET_EXTRAS(query));
  return res.data;
};

export const getParentDiscount = async ({ queryKey }) => {
  const [, id] = queryKey;
  const res = await axiosInstance.get(
    `${API.GET_PARENT_DISCOUNT}?parentId=${id}`
  );
  return res.data;
};

export const getExtras = async ({ queryKey }) => {
  const [, id] = queryKey;
  const res = await axiosInstance.get(`${API.GET_EXTRA}?divisionId=${id}`);
  return res.data;
};
export const getParentsDetails = async ({queryKey}) => {
    const [, id] = queryKey;
  const res = await axiosInstance.get(API.GET_PARENT_DETAILS(id));
  return res.data;
};


export const getExtraCount = async ({ queryKey }) => {
  const [, name, id] = queryKey;
  const res = await axiosInstance.get(
    `${API.GTE_EXTRA_COUNT}?extraName=${name}&divisionId=${id}`
  );
  return res.data;
};

export const getSurcharge = async ({ queryKey }) => {
  const [, type] = queryKey;
  const res = await axiosInstance.get(`${API.GET_SURCHARGE}?planType=${type}`);
  return res.data;
};

export const getLoanDetails = async (query) => {
  const res = await axiosInstance.get(API.GET_LOAN_DETAILS(query));
  return res.data;
};

export const setTerm = async (body) => {
  const res = await axiosInstance.post(API.SET_TERM, body);
  return res.data;
};

export const setNewTerm = async (body) => {
  const res = await axiosInstance.post(API.SET_NEW_TERM, body);
  return res.data;
};

export const updateTerm = async (body) => {
  const res = await axiosInstance.post(API.UPDATE_TERM, body);
  return res.data;
};

export const moveLoan = async (body) => {
  const res = await axiosInstance.put(API.MOVE_LOAN, body);
  return res.data;
};

export const createSurcharge = async (body) => {
  const res = await axiosInstance.post(API.CREATE_SURCHARGE, body);
  return res.data;
};

export const updateSurcharge = async (body) => {
  const res = await axiosInstance.put(API.UPDATE_SURCHARGE, body);
  return res.data;
};

export const createLoan = async (body) => {
  const res = await uploadInstance.post(API.CREATE_LOAN, body);
  return res.data;
};

export const createProfile = async (body) => {
  const res = await axiosInstance.post(API.CREATE_PROFILE, body);
  return res.data;
};

export const createDept = async (body) => {
  const res = await axiosInstance.post(API.CREATE_DEPT, body);
  return res.data;
};

export const createLevel = async (body) => {
  const res = await axiosInstance.post(API.CREATE_LEVEL, body);
  return res.data;
};

export const updateEnroll = async (body) => {
  const res = await axiosInstance.post(API.UPDATE_ENROLL, body);
  return res.data;
};

export const readNoti = async (body) => {
  const res = await axiosInstance.put(API.READ_NOTI, body);
  return res.data;
};

export const updateBank = async (body) => {
  const res = await axiosInstance.put(API.UPDATE_BANK, body);
  return res.data;
};

export const uploadLogo = async (body) => {
  const res = await uploadInstance.post(API.UPLOAD_LOGO, body);
  return res.data;
};
export const deleteParent = async (data) => {
  const res = await axiosInstance.delete(API.DELETE_PARENT, { data });
  return res.data;
};

export const deleteWard = async (query) => {
  const res = await axiosInstance.delete(API.DELETE_WARD(query));
  return res.data;
};

export const deleteEnrollment = async (query) => {
  const res = await axiosInstance.delete(API.DEL_ENROLLMENT(query));
  return res.data;
};

export const deleteClass = async (query) => {
  const res = await axiosInstance.delete(API.DELETE_CLASS(query));
  return res.data;
};

export const deleteExtra = async (query) => {
  const res = await axiosInstance.delete(API.DEL_EXTRA(query));
  return res.data;
};

export const deleteDefExtra = async (query) => {
  const res = await axiosInstance.delete(API.DEL__DEF_EXTRA(query));
  return res.data;
};

export const deleteNoti = async (query) => {
  const res = await axiosInstance.delete(API.DELETE_NOTI(query));
  return res.data;
};

export const deleteDiscount = async (query) => {
  const res = await axiosInstance.delete(API.DEL_DISCOUNT(query));
  return res.data;
};

export const getSchool = async () => {
  const res = await axiosInstance.get(`${API.GET_SCHOOL}`);
  return res.data;
};

export const getProv = async () => {
  const res = await axiosInstance.get(API.GET_LOAN_PROVIDERS);
  return res.data;
};

export const getNoti = async () => {
  const res = await axiosInstance.get(`${API.GET_NOTI}`);
  return res.data;
};

export const getAllDivisions = async ({ queryKey }) => {
  const [, id] = queryKey;
  const res = await axiosInstance.get(
    `${API.GET_DIVISION}?schoolId=${id}&status=Active&offset=0&limit=100`
  );
  return res.data;
};

export const getDefaultExtras = async () => {
  const res = await axiosInstance.get(API.GET_DEFAULT_EXTRAS);
  return res.data;
};

export const getFees = async (query) => {
  const res = await axiosInstance.get(API.GET_FEES(query.id, query.session));
  return res.data;
};

export const getParExtra = async (query) => {
  const res = await axiosInstance.get(
    API.PAR_EXTRA(query.id, query.enrollmentId)
  );
  return res.data;
};

export const getOverdueParents = async () => {
  const res = await axiosInstance.get(API.GET_OVERDUE_PARENTS);
  return res.data;
};

export const getUser = async () => {
  const res = await axiosInstance.get(API.GET_USER);
  return res.data;
};

export const getLoanHistory = async () => {
  const res = await axiosInstance.get(API.LOAN_HISTORY);
  return res.data;
};

export const getTerms = async ({ queryKey }) => {
  const [, id] = queryKey;
  const res = await axiosInstance.get(`${API.GET_TERM}?schoolId=${id}`);
  return res.data;
};
export const getTotalFee = async () => {
  const res = await axiosInstance.get(API.GET_TOTAL_FEE);
  return res.data;
};

export const getTotalOutstanding = async () => {
  const res = await axiosInstance.get(API.GET_TOTAL_OUTSTANDING);
  return res.data;
};

export const getTotalPaid = async () => {
  const res = await axiosInstance.get(API.GET_TOTAL_PAID);
  return res.data;
};

export const getTotalOverdue = async () => {
  const res = await axiosInstance.get(API.GET_TOTAL_OVERDUE);
  return res.data;
};

export const getRoles = async () => {
  const res = await axiosInstance.get(API.GET_ROLES);
  return res.data;
};

export const createRole = async (body) => {
  const res = await axiosInstance.post(API.CREATE_ROLES, body);
  return res.data;
};

export const editRole = async (body) => {
  const res = await axiosInstance.put(API.EDIT_ROLES, body);
  return res.data;
};

export const deleteRole = async (query) => {
  const res = await axiosInstance.delete(API.DEL_ROLE(query));
  return res.data;
};

export const deleteStaff = async (query) => {
  const res = await axiosInstance.delete(API.DEL_STAFF(query));
  return res.data;
};

export const getPermissions = async () => {
  const res = await axiosInstance.get(API.GET_PERMISSIONS);
  return res?.data;
};

export const getAdmins = async () => {
  const res = await axiosInstance.get(API.GET_ADMINS);
  return res?.data;
};

export const invAdmin = async (body) => {
  const res = await axiosInstance.post(API.INV_ADMIN, body);
  return res.data;
};

export const editStaff = async ({ query, body }) => {
  const response = await axiosInstance.put(API.UPDATE_ADMIN(query), body);
  return response.data;
};

export const addWardExtra = async (body) => {
  const response = await axiosInstance.post(API.ADD_WARD_EXTRA, body);
  return response.data;
};

export const removeWardExtra = async (body) => {
  const response = await axiosInstance.delete(API.REMOVE_WARD_EXTRA, { data: body });
  return response.data;
};

export const getParentOverduePlans = async (query) => {
  const res = await axiosInstance.get(API.GET_PARENT_OVERDUE_PLANS(query));
  return res.data;
};

export const getTotalSchoolFee = async () => {
  const res = await axiosInstance.get(API.GET_TOTAL_SCHOOL_FEE);
  return res.data;
};

export const getPlanTotalPaid = async (query) => {
  const res = await axiosInstance.get(API.GET_PLAN_TOTAL_PAID(query));
  return res.data;
};

export const getPlanOutstanding = async (query) => {
  const res = await axiosInstance.get(API.GET_PLAN_OUTSTANDING(query));
  return res.data;
};

export const getPlanTotalAmount = async (query) => {
  const res = await axiosInstance.get(API.GET_PLAN_TOTAL_AMOUNT(query));
  return res.data;
};

export const getExpectedPayment = async (query) => {
  const res = await axiosInstance.get(API.GET_EXPECTED_PAYMENT(query));
  return res.data;
};
