import React, { useState } from "react";
import {
  Text,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Button,
  Box,
  Image,
  useDisclosure,
  Center,
} from "@chakra-ui/react";
import useCustomToast from "../../../utils/notifications";
import { BsTrash } from "react-icons/bs";
import {
  useDeleteNoti,
  useGetNoti,
  useReadNoti,
} from "../../../services/query/school";
import { trimID } from "../../../utils/helper";
import { bell } from "../../../assets/exports";
import NotiDetails from "../../modals/NotiDetails";
import DeleteNoti from "../../modals/DeleteNoti";

export const Main = () => {
  const { data: list, refetch } = useGetNoti();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [details, setDetails] = useState("");
  const { successToast, errorToast } = useCustomToast();
  const [showDelete, setShowDelete] = useState(false);
  const [deleteData, setDeleteData] = useState("");
  const { mutate } = useReadNoti({
    onSuccess: () => {
      refetch();
    },
  });

  const unread =
    list?.data && list?.data?.filter((noti) => noti?.isRead === false);

  const markAsRead = (item) => {
    mutate({
      notificationId: item.id,
      isRead: true,
    });
  };

  const { mutate: deleteMutate, isLoading: isDeleting } = useDeleteNoti({
    onSuccess: (res) => {
      refetch();
      successToast(res?.message);
      setShowDelete(false);
    },
    onError: (res) => {
      errorToast(
        res?.response?.data?.message || res?.message || "An Error Occured"
      );
    },
  });

  const handleDelete = () => {
    deleteMutate(deleteData);
  };

  const open = (item) => {
    if (item.isRead === true) {
      ("");
    } else {
      markAsRead(item);
    }
    setDetails(item);
    onOpen();
  };
  return (
    <Popover>
      <PopoverTrigger>
        <Button
          bg="transparent"
          position={"relative"}
          p="0"
          _hover={{ outline: "none" }}
          boxShadow="none"
          _active={{ outline: "none" }}
          _focus={{ outline: "none" }}
        >
          <Image w="64px" h="64px" src={bell} />
          {list?.data && unread?.length === 0
            ? ""
            : list?.data && (
                <Flex
                  justifyContent="center"
                  align="center"
                  bg={"red"}
                  textColor={"white"}
                  fontWeight={"extrabold"}
                  h="20px"
                  w="20px"
                  rounded={"full"}
                  position={"absolute"}
                  top={"-1"}
                  fontSize={"12px"}
                  ml={"9"}
                >
                  {unread?.length === 0 ? "" : unread?.length}
                </Flex>
              )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        boxShadow="lg"
        color="darkBg"
        mt="5px"
        borderRadius="8px"
        h="30vh"
        bg="#fff"
      >
        <PopoverHeader>
          <Flex alignItems="center" justifyContent="space-between">
            <Text size="sm">Notification</Text>
          </Flex>
        </PopoverHeader>
        <PopoverBody overflowY={"scroll"} height={"250px"}>
          {list?.data?.length > 0 ? (
            unread?.map((item, i) => (
              <Flex
                key={i}
                py="7px"
                px="11px"
                cursor="pointer"
                gap="10px"
                alignItems="flex-start"
              >
                <Box
                  color="red"
                  cursor="pointer"
                  onClick={() => {
                    setShowDelete(true);
                    setDeleteData(item?.id);
                  }}
                >
                  <BsTrash />
                </Box>
                <Box onClick={() => open(item)}>
                  <Text
                    fontWeight={500}
                    fontSize="13px"
                    color={item.isRead === true ? "#000" : "blueBg"}
                  >
                    {item?.title}
                  </Text>
                  <Text mt="4px" fontSize="13px" color="#bdbdbd">
                    {trimID(item?.body)}
                  </Text>
                </Box>
              </Flex>
            ))
          ) : (
            <Center fontWeight={500}>No notifications at the moment</Center>
          )}
        </PopoverBody>
      </PopoverContent>
      <NotiDetails data={details} isOpen={isOpen} onClose={onClose} />
      <DeleteNoti
        action={handleDelete}
        isLoading={isDeleting}
        isOpen={showDelete}
        onClose={() => setShowDelete(false)}
      />
    </Popover>
  );
};
