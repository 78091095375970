import { lazy } from "react";
import WithSuspense from "../components/Loaders/WithSuspense";
import { PUBLIC_PATHS, PRIVATE_PATHS } from "./constants";
import { Navigate } from "react-router-dom";

const { LOGIN, REG_1, REG_2, VER_PASS, CH_PASS, FOG_PASS } = PUBLIC_PATHS;

const {
  DASHBOARD,
  EXTRAS,
  AUDIT,
  SETUP,
  TRAN_LOG,
  PAR_MGT,
  NOTI,
  SETTINGS,
  LOAN,
  TEAMS,
  ROLES,
  PORTAL,
} = PRIVATE_PATHS;

const Login = WithSuspense(lazy(() => import("../pages/Authentication/Login")));
const Reg1 = WithSuspense(
  lazy(() => import("../pages/Authentication/RegisterOne"))
);
const Reg2 = WithSuspense(
  lazy(() => import("../pages/Authentication/RegisterTwo"))
);
const ChangePassword = WithSuspense(
  lazy(() => import("../pages/Authentication/ChangePassword"))
);
const ForgotPassword = WithSuspense(
  lazy(() => import("../pages/Authentication/ForgotPassword"))
);
const VerifyCodePass = WithSuspense(
  lazy(() => import("../pages/Authentication/VerifyCodePass"))
);
const Dashboard = WithSuspense(
  lazy(() => import("../pages/Dashboard/Dashboard"))
);
const Setup = WithSuspense(lazy(() => import("../pages/Setup/Setup")));
const AuditLog = WithSuspense(lazy(() => import("../pages/AuditLog/AuditLog")));
const TranLog = WithSuspense(lazy(() => import("../pages/TranLog/TranLog")));
const Portal = WithSuspense(lazy(() => import("../pages/Portal/Portal")));
const Roles = WithSuspense(lazy(() => import("../pages/Roles/Roles")));
const Teams = WithSuspense(lazy(() => import("../pages/Teams/Teams")));
const Settings = WithSuspense(lazy(() => import("../pages/Settings/Settings")));
const ParentMgt = WithSuspense(
  lazy(() => import("../pages/ParentMgt/ParentMgt"))
);
const Extras = WithSuspense(
  lazy(() => import("../components/data/Portal/Extras"))
);
const Loan = WithSuspense(lazy(() => import("../pages/Loan/Loan")));
const Notifications = WithSuspense(
  lazy(() => import("../pages/Notifications/Notifications"))
);

export const PUBLIC_ROUTES = [
  { path: LOGIN, element: <Login /> },
  { path: REG_1, element: <Reg1 /> },
  { path: REG_2, element: <Reg2 /> },
  { path: FOG_PASS, element: <ForgotPassword /> },
  { path: CH_PASS, element: <ChangePassword /> },
  { path: VER_PASS, element: <VerifyCodePass /> },
  { path: "*", element: <Navigate to="/login" replace /> },
];

export const PRIVATE_ROUTES = [
  { path: EXTRAS, element: <Extras /> },
  { path: DASHBOARD, element: <Dashboard /> },
  { path: TRAN_LOG, element: <TranLog /> },
  { path: PAR_MGT, element: <ParentMgt /> },
  { path: AUDIT, element: <AuditLog /> },
  { path: NOTI, element: <Notifications /> },
  { path: SETUP, element: <Setup /> },
  { path: SETTINGS, element: <Settings /> },
  { path: LOAN, element: <Loan /> },
  { path: PORTAL, element: <Portal /> },
  { path: ROLES, element: <Roles /> },
  { path: TEAMS, element: <Teams /> },
  { path: "*", element: <Navigate to="/complete-setup" replace /> },
];
