import React from "react";
import {
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputLeftAddon,
  Image,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { flag } from "../../assets/exports";

const PhoneInput = ({
  id,
  onChange,
  onBlur,
  value,
  onFocus,
  show,
  onKeyPress,
  name,
  placeholder,
  type,
  onClick,
  isDisabled,
  error,
  password,
  mb,
}) => {
  return (
    <FormControl mb={mb ? 0 : 5} id={id} isInvalid={error}>
      <InputGroup
        width="full"
        color={error ? "red" : value ? "#7B47CC" : "#BDBDBD"}
      >
        <InputLeftAddon
          h="60px"
          bg={value ? "#E8EEFD" : "#f9f9f9"}
          border={error ? "1px solid red" : "1px solid rgba(15, 23, 43, 0.3)"}
        >
          <Flex color={value ? "#000" : "gray"} align="center" gap="5px">
            <Image src={flag} w="30px" h="15px" />
            +234
          </Flex>
        </InputLeftAddon>
        <Input
          bg={value ? "#E8EEFD" : "#f9f9f9"}
          border={error ? "1px solid red" : "1px solid rgba(15, 23, 43, 0.3)"}
          type={type}
          h="60px"
          name={name}
          onKeyPress={onKeyPress}
          isReadOnly={isDisabled}
          value={value}
          onFocus={onFocus}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          variant="secondary"
        />
        <InputRightElement
          cursor="pointer"
          h="60px"
          display={show ? "flex" : "none"}
          onClick={onClick}
        >
          {!password ? (
            <FaEyeSlash color={value ? "#0F172B" : "#4F4F4F"} />
          ) : (
            <FaEye color={value ? "#0F172B" : "#4F4F4F"} />
          )}
        </InputRightElement>
      </InputGroup>
      {error && (
        <Text fontSize="12px" color="red">
          {error}
        </Text>
      )}
    </FormControl>
  );
};

export default PhoneInput;
